import React, {Component} from "react";
import { languageText, createMenteeDataFields} from "../config";
import DataOverview from "../chcomponents/DataOverview";
import {connect} from "react-redux";
import {getList} from "../client/actions/apiActions";
import Loader from "../components/Loader";

const tableName = "mentee";

class Mentees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: true,
                },
                {
                    dataField: "image",
                    type: "image",
                    sort: true
                },
                {
                    dataField: "firstName",
                    sort: true
                },
                {
                    dataField: "middleName",
                    sort: true
                },
                {
                    dataField: "lastName",
                    sort: true
                },
                {
                    dataField: "email",
                    sort: true
                },
                {
                    dataField: "address",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: createMenteeDataFields,
            fieldDataDefault: {},
        }
    }
    componentDidMount()
    {
        let organizationId = '';
        if (typeof(this.props.match.params.organizationId) !== 'undefined') {
            organizationId = this.props.match.params.organizationId;
        }
        if (organizationId === '') {
            if (typeof(this.props.auth.user.organizationId) !== 'undefined') {
                organizationId = this.props.auth.user.organizationId;
            }
            //organizationId = this.props.auth.user.user.organizationId;
        }
        this.setState({organizationId: organizationId, pageMode: 'data'});
        this.props.getList('configGenders', ['configSexualOrientation']);
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps !== this.props)
        {
            if (typeof(this.props.data.tableName) !== 'undefined')
            {
                if (this.props.data.tableName === "configGenders")
                {
                    let fieldData = this.state.fieldData;
                    fieldData.map((data, index) => {
                        if (data.fieldName === 'gender') {
                            fieldData[index].dropdownData = this.props.data.configGenders;
                        }
                        if (data.fieldName === 'sexualOrientation') {
                            fieldData[index].dropdownData = this.props.data.configSexualOrientation;
                        }
                        return null;
                    });
                    this.setState({pageMode: 'data', fieldData});
                }
            }
        }
    }

    render() {
        const { tableColumns, fieldData, fieldDataDefault, organizationId, pageMode } = this.state;
        let languageCode = '';
        if (typeof(this.props.auth.user.user) !== 'undefined'){
            languageCode = this.props.auth.user.user.languageCode;
        } else if (typeof(this.props.auth.user) !== 'undefined') {
            languageCode = this.props.auth.user.languageCode;
        }
        if (pageMode === 'loading') return (<Loader/>);
        if (pageMode === 'data') {
            return (<DataOverview
                tableName={tableName}
                organizationId={organizationId}
                sortFieldName={"lastName"}
                sortFieldDirection={"ASC"}
                pageTitle={languageText[languageCode].components[tableName].pageTitle}
                listTitle={languageText[languageCode].components[tableName].listTitle}
                listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
                addTitle={languageText[languageCode].components[tableName].addTitle}
                addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
                editTitle={languageText[languageCode].components[tableName].editTitle}
                editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
                languageInfo={languageText[languageCode]}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
            />);
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(Mentees);
