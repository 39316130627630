import React, {Component} from "react";
import DataOverview from "../chcomponents/DataOverview";
import {languageText} from "../config"; // Import css

const tableName = "languages";
class Languages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "name",
                    text: "Name",
                    sort: true
                },
                {
                    dataField: "code",
                    text: "Code",
                    sort: true
                },
                {
                    dataField: "flag",
                    text: "Flag",
                    type: "image",
                    sort: true
                },
                {
                    dataField: "tools",
                    text: "Tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    displayName: "Name",
                    placeholder: "name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                    requiredMessage: "Name is required!"
                },
                {
                    fieldName: "code",
                    displayName: "Code",
                    placeholder: "code",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                    requiredMessage: "Name is required!"
                },
                {
                    fieldName: "flag",
                    displayName: "Flag",
                    placeholder: "",
                    fieldType: "image",
                    colSize: 1
                },
            ],
            fieldDataDefault: {
                name: '',
                active: 1,
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            pageTitle="Languages"
            listTitle="Languages"
            listSubTitle="This is the list of languages."
            addTitle="Add language"
            addSubTitle="Add a new language"
            editTitle="Edit language"
            editSubTitle="Edit a language"
            tableColumns={tableColumns}
            fieldData={fieldData}
            languageInfo={languageText[languageCode]}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default Languages;
