import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleSidebar, hideSidebar, toggleSidebarNew } from "../client/actions/sidebarActions";
import { ApiURL, languageText } from "../config";
import { Link } from "react-router-dom";
import store from "../client/store";
import { getNotifications } from "../client/actions/apiActions";
import { Button, Row, Col, Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from "reactstrap";
import { AlertCircle, Bell, Bell as NotificationsIcon, Home, MessageCircle, Settings, MessageSquare as MessageIcon, User, UserPlus, Power as LogoutIcon } from "react-feather";

class NavbarComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirect: false,
			defaultLanguage: 'en',
			notifications: [],
			messages: []
		};
		this.intervalHandle = null;
		this.startTimer = this.startTimer.bind(this);
		this.stopTimer = this.stopTimer.bind(this);
	}
	componentDidMount() {
		this.setState({
			notifications: this.props.auth.user.notifications,
			messages: this.props.auth.user.messages
		});
		this.startTimer();
	}
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			if (this.state.notifications !== this.props.notifications) {
				this.setState({
					notifications: this.props.auth.user.notifications,
					messages: this.props.auth.user.messages
				});
			}

		}
	}

	updateLanguage = (languageCode) => {
		const formData = new FormData();
		const token = store.getState().auth.token;
		formData.append('languageCode', languageCode);
		formData.append('updateType', 'lang');
		fetch(`${ApiURL}/update-profile`, {
			method: 'POST',
			headers: {'Authorization': token},
			body: formData
		})
			.then(res => res.json())
			.then(output => {
				if (output.success === 1 || output.success === '1')
				{
					window.location.replace("/");
				}
			})
	};
	windowLinkClick = (linkTo) => {
		window.location.href = linkTo;
	}

	componentWillUnmount() {
		this.stopTimer();
	}
	checkNotifications = () => {
		// this.props.getNotifications();
	};

	startTimer = () => {
		this.stopTimer();
		if (this.intervalHandle === null) {
			this.intervalHandle = setInterval(this.checkNotifications, 30000);
		}
	};
	stopTimer = () => {
		if (this.intervalHandle !== null) {
			clearInterval(this.intervalHandle);
			this.intervalHandle = null;
		}
	};

	render() {
		const { messages, notifications } = this.state;
		let userInfo = {};
		let languages = {};
		let selectedLang = "en";

		if (typeof(this.props.auth) !== 'undefined' && this.props.auth !== null) {
			if (typeof(this.props.auth.user) !== 'undefined' && this.props.auth.user !== null) {
				userInfo = this.props.auth.user.user;
				languages = this.props.auth.user.languages;
				selectedLang = userInfo.languageCode;
			}
		}
		let avatar = '';
		if (typeof(userInfo.image) === 'undefined' || userInfo.image === '') avatar = "/assets/img/avatars/default.jpg";

		return (<Navbar color="white" light expand>
        <span
	        className="sidebar-toggle d-flex mr-2"
	        onClick={() => toggleSidebarNew()}
        >
          <i className="hamburger align-self-center" />
        </span>

			<Collapse navbar>
				<Nav className="ml-auto" navbar>
					<NavbarDropdown
						header={languageText[selectedLang].navBar.newMessages}
						footer={languageText[selectedLang].navBar.showAllMessages}
						footerLink={() => this.windowLinkClick("/chat")}
						icon={MessageCircle}
						count={messages.length}
						//showBadge
					>
						{messages.map((item, key) => {
							return (
								<NavbarDropdownItem
									key={key}
									icon={
										<img
											className="avatar img-fluid rounded-circle"
											src={item.avatar}
											alt={item.name}
										/>
									}
									onClick={() => this.windowLinkClick("/chat/" + item.roomId)}
									title={item.name}
									description={item.message}
									time={item.time}
									messageCount={item.messageCount}
									spacing
								/>
							);
						})}
					</NavbarDropdown>

					<NavbarDropdown
						header={languageText[selectedLang].navBar.newNotifications}
						footer={languageText[selectedLang].navBar.showAllNotifications}
						footerLink={() => this.windowLinkClick("/notifications")}
						icon={NotificationsIcon}
						count={notifications.length}
						showBadge
					>
						{notifications.map((item, key) => {
							let notificationUrl = "/notifications";
							if (item.notificationUrl !== '') notificationUrl = item.notificationUrl;

							let icon = <Bell size={18} className="text-warning" />;
							if (item.notificationType === "important") {
								icon = <AlertCircle size={18} className="text-danger" />;
							}
							if (item.notificationType === "message") {
								icon = <MessageIcon size={18} className="text-dark" />;
							}
							if (item.notificationType === "login") {
								icon = <Home size={18} className="text-primary" />;
							}
							if (item.notificationType === "request") {
								icon = <UserPlus size={18} className="text-success" />;
							}
							return (<NavbarDropdownItem
								key={key}
								icon={icon}
								onClick={() => this.windowLinkClick(notificationUrl)}
								title={item.notificationSubject}
								description={item.notificationMessage}
								time={item.time}
							/>);
						})}
					</NavbarDropdown>

					<UncontrolledDropdown nav inNavbar className="mr-2">
						{ userInfo.language && <DropdownToggle nav caret className="nav-flag">
							<img src={userInfo.language.flag} alt={userInfo.language.name} />
						</DropdownToggle>}
						{ languages && languages.length > 0 && <DropdownMenu right>
							{ languages.map((lang, index) => {
								return (<DropdownItem key={index} onClick={() => this.updateLanguage(lang.code)}>
									<img
										src={lang.flag}
										alt={lang.name}
										width="20"
										className="align-middle mr-1"
									/>
									<span className="align-middle">{lang.name} ({lang.code})</span>
								</DropdownItem>);
							})}
						</DropdownMenu>}
					</UncontrolledDropdown>

					<UncontrolledDropdown nav inNavbar>
                <span className="d-inline-block d-sm-none">
                  <DropdownToggle nav caret>
                    <Settings size={18} className="align-middle" />
                  </DropdownToggle>
                </span>
						<span className="d-none d-sm-inline-block">
                  <DropdownToggle nav caret>
                    {avatar && <img
	                    src={avatar}
	                    className="avatar img-fluid rounded-circle mr-1"
	                    alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    />}
	                  <span className="text-dark">{userInfo.firstName} {userInfo.lastName}</span>
                  </DropdownToggle>
                </span>
						<DropdownMenu right>
							<DropdownItem>
								<Link to="/profile">
									<User size={18} className="align-middle mr-2" />
									{languageText[selectedLang].navBar.profile}
								</Link>
							</DropdownItem>

							<DropdownItem divider />

							<DropdownItem>
								<Link to="/auth/sign-out">
									<LogoutIcon size={18} className="align-middle mr-2" />
									{languageText[selectedLang].navBar.signOut}
								</Link>
							</DropdownItem>

						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
			</Collapse>
		</Navbar>);
	}
}

const NavbarDropdown = ({
	                        children,
	                        count,
	                        showBadge,
	                        header,
	                        footer,
	                        footerLink,
	                        icon: Icon
                        }) => {
	if (count === 0) showBadge = false;
	return (
		<UncontrolledDropdown nav inNavbar className="mr-2">
			<DropdownToggle nav className="nav-icon dropdown-toggle">
				<div className="position-relative">
					<Icon className="align-middle" size={18} />
					{showBadge ? <span className="indicator">{count}</span> : null}
				</div>
			</DropdownToggle>
			<DropdownMenu right className="dropdown-menu-lg py-0">
				<div className="dropdown-menu-header position-relative">
					{count} {header}
				</div>
				<div className="notification-list">
					<ListGroup>{children}</ListGroup>
				</div>
				{count !== 0 && <DropdownItem header className="dropdown-menu-footer">
					{ typeof(footerLink) !== 'undefined' && footerLink !== '' && footerLink !== 'undefined' && <Button color="link" onClick={footerLink}><span className="text-muted">{footer}</span></Button>}
					{ typeof(footerLink) === 'undefined' && <span className="text-muted">{footer}</span>}
				</DropdownItem>}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}

const NavbarDropdownItem = ({ icon, title, description, time, spacing, onClick, messageCount }) => (
	<ListGroupItem onClick={onClick}>
		<Row noGutters className="align-items-center cursor-pointer">
			<Col xs={2}>{icon}</Col>
			<Col xs={10} className={spacing ? "pl-2" : null}>
				<div className="text-dark">{title}</div>
				<div className="text-muted small mt-1">{description}</div>
				<div className="text-muted small mt-1">{time}</div>
				{messageCount ? <span className="indicator">{messageCount}</span> : null}
			</Col>
		</Row>
	</ListGroupItem>
);

const mapDispatchToProps = dispatch => {
	return {
		getNotifications: () => dispatch(getNotifications()),
		toggleSidebar: () => dispatch(toggleSidebar()),
		hideSidebar: () => dispatch(hideSidebar()),
		toggleSidebarNew: () => dispatch(toggleSidebarNew()),
		dispatch
	}
}

export default connect(store => ({
	sidebar: store.sidebar,
	app: store.app,
	auth: store.auth,
	data: store.pages.data,
}), mapDispatchToProps ) (NavbarComponent);
