import {
  Settings as SettingsIcon,
  MessageSquare as MessageSquareIcon,
  Globe as OrganizationIcon,
  Users as MenteeIcon,
  Users as TeamMembersIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon
} from "react-feather";

// Auth
import SignIn from "../chpages/auth/SignIn";
import SignUp from "../chpages/auth/SignUp";
import SignOut from "../chpages/auth/SignOut";
import ResetPassword from "../chpages/auth/ResetPassword";
import Page404 from "../chpages/errors/Page404";
import Page500 from "../chpages/errors/Page500";

// Notifications
import Notifications from "../chcomponents/ProfileNotifications";

// COMING HOME Pages
import Chat from "../chpages/Chat";
import Organizations from "../chpages/Organizations";
import TeamMembers from "../chpages/Users";
import EmailTemplates from "../chpages/EmailTemplates";

// default pages
import Profile from "../chcomponents/Profile";

// Documentation
import AdverseExperiences from "../chpages/AdverseExperiences";
import AgencyServices from "../chpages/AgencyServices";
import Genders from "../chpages/Genders";
import ProgramStatus from "../chpages/ProgramStatus";
import SexualOrientation from "../chpages/SexualOrientation";
import StaffCertification from "../chpages/StaffCertification";
import OfferTypes from "../chpages/OfferTypes";
import TraumaInformStatus from "../chpages/TraumaInformStatus";
import LevelOfCare from "../chpages/LevelOfCare";
import Languages from "../chpages/Languages";
import AgencyTypes from "../chpages/AgencyTypes";
import Goals from "../chpages/Goals";
import Tasks from "../chpages/Tasks";
import Challenges from "../chpages/Challenges";
import Jobs from "../chpages/Jobs";
import ELearning from "../chpages/ELearning";
import ProfilePassword from "../chcomponents/ProfilePassword";
import ProfileFiles from "../chcomponents/ProfileFiles";
import Content from "../chpages/Content";
import ContentPage from "../chcomponents/ContentPage";
import Mentees from "../chpages/Mentees";

import MenteeAssessments from "../chpages/MenteeAssessments";
import MenteeChallenges from "../chpages/MenteeChallenges";
import MenteeChat from "../chpages/MenteeChat";
import MenteeFiles from "../chpages/MenteeFiles";
import MenteeGoals from "../chpages/MenteeGoals";
import MenteeNotes from "../chpages/MenteeNotes";
import MenteeProfile from "../chpages/MenteeProfile";
import MenteePassword from "../chpages/MenteePassword";
import MenteeTasks from "../chpages/MenteeTasks";
import Resources from "../chpages/Resources";
import Dashboard from "../chpages/Dashboard";
import MenteeExport from "../chpages/MenteeExport";
import UserRoles from "../chpages/UserRoles";
import Users from "../chpages/Users";
import BugTracker from "../chpages/BugTracker";

// Routes
const landingRoutes = {
  path: "/confirm-invitation",
  name: "Confirm Invitation",
  component: SignUp,
  isPrivate: false,
  children: null
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  langName: "dashboard",
  component: Dashboard,
  icon: SlidersIcon,
  containsHome: true,
  isPrivate: true,
  children: null
};
const chatRoutes = {
  path: "/chat",
  name: "Chat",
  langName: "chat",
  component: Chat,
  isPrivate: true,
  // badgeColor: "primary",
  // badgeText: "5",
  icon: MessageSquareIcon,
  containsHome: true,
  children: null
};

const organizationRoutes = {
  path: "/organizations/manage",
  name: "Organizations",
  langName: "organizations",
  icon: OrganizationIcon,
  children: null,
  isPrivate: true,
  component: Organizations
}

const teamMembersRoutes = {
  path: "/organizations/users",
  name: "Team Members",
  langName: "users",
  icon: TeamMembersIcon,
  children: null,
  isPrivate: true,
  component: Users
}

const menteeRoutes = {
  path: "/mentee",
  name: "Mentees",
  langName: "mentees",
  icon: MenteeIcon,
  children: [
    {
      isPrivate: true,
      path: "/mentee/manage",
      name: "Manage Mentees",
      langName: "manageMentees",
      component: Mentees
    },
    {
      isPrivate: true,
      path: "/mentee/goals",
      name: "Goals",
      langName: "goals",
      component: Goals
    },
    {
      isPrivate: true,
      path: "/mentee/tasks",
      name: "Tasks",
      langName: "tasks",
      component: Tasks
    },
    {
      isPrivate: true,
      path: "/mentee/challenges",
      name: "Challenges",
      langName: "challenges",
      component: Challenges
    },
    {
      isPrivate: true,
      path: "/mentee/jobs",
      name: "Jobs",
      langName: "jobs",
      component: Jobs
    },
    {
      isPrivate: true,
      path: "/mentee/elearning",
      name: "e-Learning",
      langName: "eLearning",
      component: ELearning
    },
    {
      isPrivate: true,
      path: "/mentee/resources",
      name: "Resources",
      langName: "resources",
      component: Resources
    }
  ]
}

// SETTINGS LINK
const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  langName: "settings",
  icon: SettingsIcon,
  children: [
    {
      isPrivate: true,
      path: "/settings/adverse-experiences",
      name: "Adverse Experiences",
      langName: "adverseExperiences",
      component: AdverseExperiences
    },
    {
      isPrivate: true,
      path: "/settings/bug-tracker",
      name: "Bug Tracker",
      langName: "bugTracker",
      component: BugTracker
    },
    {
      isPrivate: true,
      path: "/settings/agency-services",
      name: "Agency Services",
      langName: "agencyServices",
      component: AgencyServices
    },
    {
      isPrivate: true,
      path: "/settings/agency-types",
      name: "Agency Types",
      langName: "agencyTypes",
      component: AgencyTypes
    },
    {
      isPrivate: true,
      path: "/settings/email-templates",
      name: "Email Templates",
      langName: "emailTemplates",
      component: EmailTemplates
    },
    {
      isPrivate: true,
      path: "/settings/genders",
      name: "Genders",
      langName: "genders",
      component: Genders
    },
    {
      isPrivate: true,
      path: "/settings/program-status",
      name: "Program Status",
      langName: "programStatus",
      component: ProgramStatus
    },
    {
      isPrivate: true,
      path: "/settings/sexual-orientation",
      name: "Sexual Orientation",
      langName: "sexualOrientation",
      component: SexualOrientation
    },
    {
      isPrivate: true,
      path: "/settings/level-of-care",
      name: "Level of Care",
      langName: "levelOfCare",
      component: LevelOfCare
    },
    {
      isPrivate: true,
      path: "/settings/trauma-inform-status",
      name: "Trauma Inform Status",
      langName: "traumaInformStatus",
      component: TraumaInformStatus
    },
    {
      isPrivate: true,
      path: "/settings/offer-types",
      name: "Offer Types",
      langName: "offerTypes",
      component: OfferTypes
    },
    {
      isPrivate: true,
      path: "/settings/staff-certifications",
      name: "Staff Certification",
      langName: "staffCertification",
      component: StaffCertification
    },
    {
      isPrivate: true,
      path: "/settings/languages",
      name: "Languages",
      langName: "languages",
      component: Languages
    },
    {
      isPrivate: true,
      path: "/settings/content",
      name: "Content",
      langName: "content",
      component: Content
    },
    {
      isPrivate: true,
      path: "/settings/userRoles",
      name: "User Roles",
      langName: "userRoles",
      component: UserRoles
    },
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      isPrivate: false,
      component: SignIn
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      isPrivate: false,
      component: SignOut
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      isPrivate: false,
      component: ResetPassword
    },
    {
      path: "/admin/login",
      name: "Sign In",
      isPrivate: false,
      component: SignIn
    }
  ]
};

// ROUTES NOT VISIBLE IN THE SIDEBAR
const privateRoutes = {
  path: "/privacy",
  name: "Privacy",
  children: [
    {
      path: "/mentee/manage/:menteeId",
      name: "Mentee Profile",
      isPrivate: true,
      component: MenteeProfile
    },
    {
      path: "/organizations/users/:organizationId",
      name: "Organization Users",
      isPrivate: true,
      component: TeamMembers
    },
    {
      path: "/mentee/mentee-export/:menteeId",
      name: "Mentee Profile",
      isPrivate: true,
      component: MenteeExport
    },
    {
      path: "/mentee/chat/:menteeId",
      name: "Mentee Chat",
      isPrivate: true,
      component: MenteeChat
    },
    {
      path: "/mentee/mentee-tasks/:menteeId",
      name: "Mentee Tasks",
      isPrivate: true,
      component: MenteeTasks
    },
    {
      path: "/mentee/mentee-password/:menteeId",
      name: "Mentee Password",
      isPrivate: true,
      component: MenteePassword
    },
    {
      path: "/mentee/mentee-goals/:menteeId",
      name: "Mentee Goals",
      isPrivate: true,
      component: MenteeGoals
    },
    {
      path: "/mentee/mentee-challenges/:menteeId",
      name: "Mentee Challenges",
      isPrivate: true,
      component: MenteeChallenges
    },
    {
      path: "/mentee/mentee-notes/:menteeId",
      name: "Mentee Notes",
      isPrivate: true,
      component: MenteeNotes
    },
    {
      path: "/mentee/mentee-assessments/:menteeId",
      name: "Mentee Assessments",
      isPrivate: true,
      component: MenteeAssessments
    },
    {
      path: "/mentee/mentee-files/:menteeId",
      name: "Mentee Files",
      isPrivate: true,
      component: MenteeFiles
    },
    {
      path: "/chat/:chatRoom",
      name: "Chat Room",
      isPrivate: true,
      component: Chat
    },
    {
      path: "/privacy",
      name: "Privacy",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/support-center",
      name: "Support",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/help-center",
      name: "Help Center",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/terms-of-service",
      name: "Terms of Service",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/profile",
      name: "Profile",
      isPrivate: true,
      component: Profile
    },
    {
      path: "/profile-password",
      name: "Password",
      isPrivate: true,
      component: ProfilePassword
    },
    {
      path: "/profile-files",
      name: "Files",
      isPrivate: true,
      component: ProfileFiles
    },
    {
      path: "/profile-notifications",
      name: "Notifications",
      isPrivate: true,
      component: Notifications
    },
    {
      path: "/notifications",
      name: "Notifications",
      isPrivate: true,
      component: Notifications
    },
    {
      path: "/404",
      name: "404 Page",
      isPrivate: false,
      component: Page404
    },
    {
      path: "/500",
      name: "500 Page",
      isPrivate: false,
      component: Page500
    },
    {
      path: "/auth/sign-out",
      name: "sign out",
      isPrivate: true,
      component: SignOut
    }
  ]
};

// ADD  ROUTES - if not added here can not be visited
// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  organizationRoutes,
  teamMembersRoutes,
  menteeRoutes,
  chatRoutes,
  settingsRoutes,
  privateRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// ROUTES TO SHOW IN NAVBAR
export default [
  dashboardRoutes,
  chatRoutes,
  organizationRoutes,
  teamMembersRoutes,
  menteeRoutes,
  settingsRoutes,
];
