import React, {Component} from "react";
import {Container } from "reactstrap";
import DataList  from "./DataList";
import DataEdit  from "./DataEdit";
import { getTable, getMenteeTable, getSubDataTable, archiveSubDataRecord, getRecord, getMentee, saveRecord, archiveRecord, deleteRecord, exportOverview } from "../client/actions/apiActions";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {toastr} from "react-redux-toastr";
import {ApiURL, languageText} from "../config";

class SubDataOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            pageTitle: '',
            showExportData: true,
            listTitle: '',
            addTitle: '',
            editTitle: '',
            listSubTitle: '',
            addSubTitle: '',
            editSubTitle: '',
            tableColumns : [],
            tableData: [],
            fieldData: [],
            fieldDataResults: {},
            fieldDataDefault: {},
            fieldDataErrors: [],
            totalPages: 1,
            totalRecords: 0,
            menteeId: 0,
            searchText: '',
            changeValue: '',
            currentPage: 1,
            recordsPerPage: 25,
            sortDirection: 'ASC',
            sortFieldName: 'default',
            settings: {},

            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            position: "top-right",
            key: 0
        }
    }
    componentDidMount() {
        let showExportData = this.props.showExportData;
        if (showExportData !== false) showExportData = true;

        let singleEdit = this.props.singleEdit;
        if (singleEdit !== true) singleEdit = false;
        let settings = {};
        if (typeof(this.props.settings) !== 'undefined') {
            settings = this.props.settings;
        }

        let recordsPerPage = this.state.recordsPerPage;
        if (typeof(this.props.recordsPerPage) !== 'undefined') {
            recordsPerPage = this.props.recordsPerPage;
        }

        let menteeId = this.props.menteeId;
        if (typeof(menteeId) === 'undefined') menteeId = "";
        if (menteeId !== '' && this.props.tableName === 'mentee') {
            this.props.getRecord("mentee", menteeId);
        }

        if (singleEdit === true && this.props.tableName === 'menteeUserAssessments')
        {
            this.props.getRecord("menteeUserAssessments", menteeId);
        }
        else if (singleEdit === false)
        {
            this.props.getMenteeTable(this.props.tableName, 1, recordsPerPage, '', '', '', menteeId);
        }

        this.setState({
            singleEdit: singleEdit,
            tableName: this.props.tableName,
            showExportData: showExportData,
            settings: settings,

            pageTitle: this.props.pageTitle,
            listTitle: this.props.listTitle,
            listSubTitle: this.props.listSubTitle,
            addTitle: this.props.addTitle,
            addSubTitle: this.props.addSubTitle,
            editTitle: this.props.editTitle,
            editSubTitle: this.props.editSubTitle,

            tableColumns : this.props.tableColumns,
            fieldData: this.props.fieldData,
            recordsPerPage: recordsPerPage,
            menteeId: menteeId
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data.dataType === "export" && this.props.data.status === 1 && this.props.data.code !== '' && typeof(this.props.data.code) !== 'undefined')
            {
                window.open(ApiURL + "/export/" + this.props.data.code);
            }
            else if (this.props.data.dataType === "addRecord")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    pageMode: "add"
                });
            }
            else if (this.props.data.dataType === "getRecord")
            {
                if (this.state.singleEdit === true)
                {
                    this.setState({
                        fieldDataResults: this.props.data.dataValues,
                        pageMode: "edit"
                    });
                }
                else
                {
                    this.setState({
                        fieldDataResults: this.props.data.dataValues,
                        pageMode: "edit"
                    });
                }
            }
            else if (this.props.data.dataType === "getMentee")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    //pageMode: "edit"
                });
            }
            else if (this.props.data.dataType === "deleteRecord")
            {
                this.refreshSubFunction();
            }
            else if (this.props.data.dataType === "saveRecord")
            {
                if (this.props.data.status === 1) {
                    this.showToastr("success", "Save Successful", "Record was successfully saved.");
                } else {
                    this.showToastr("danger", "Error Saving", this.props.data.message);
                }
                if (this.state.singleEdit !== true) {
                    this.refreshSubFunction();
                } else {



                    this.props.getRecord(this.state.tableName, this.props.data.recordId);
                }
            }
            else if (this.props.data.dataType === "archiveRecord")
            {
                this.refreshSubFunction();
            }
            else if (this.props.data.dataType === "getTable")
            {
                this.setState({
                    tableData: this.props.data.dataValues,
                    currentPage: this.props.data.currentPage,
                    totalPages: this.props.data.totalPages,
                    totalRecords: this.props.data.totalRecords,
                    pageMode: "list"
                });
            }
        }
    }
    refreshSubFunction = (currentPage, recordsPerPage, sortFieldName, sortDirection, searchText) => {
        this.setState({ pageMode: "loading" });

        let menteeId = this.state.menteeId;
        if (typeof(currentPage) === 'undefined') currentPage = this.state.currentPage;
        if (typeof(recordsPerPage) === 'undefined') recordsPerPage = this.state.recordsPerPage;
        if (typeof(sortFieldName) === 'undefined') sortFieldName = this.state.sortFieldName;
        if (typeof(sortDirection) === 'undefined') sortDirection = this.state.sortDirection;
        if (typeof(searchText) === 'undefined') searchText = this.state.searchText;
        this.props.getMenteeTable(this.state.tableName, currentPage, recordsPerPage, sortFieldName, sortDirection, searchText, menteeId);
    };
    addFunction = () => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, '0');
    }
    editFunction = (id) => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, id);
    }
    deleteFunction = (id) => {
        this.props.deleteRecord(this.state.tableName, id);
    }
    archiveRecord = (id) => {
        this.props.archiveSubDataRecord(this.state.tableName, id);
    }
    updatePage = (currentPage) => {
        this.setState({currentPage: currentPage});
        this.refreshSubFunction(currentPage, this.state.recordsPerPage);
    };
    deleteConfirmFunction = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you wish to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteFunction(id)
                },
                {
                    label: 'No'
                }
            ]
        });
    };
    saveFunction = (id, data) => {
        let prevPageMode = this.state.pageMode;
        this.setState({ pageMode: "loading"});
        let errors = 0;
        let languageCode = '';
        if (typeof(this.props.auth.user.languageCode) !== 'undefined') {
            languageCode = this.props.auth.user.languageCode;
        }
        if (typeof(this.props.auth.user.user) !== 'undefined') {
            if (typeof(this.props.auth.user.user.languageCode) !== 'undefined') {
                languageCode = this.props.auth.user.languageCode;
            }
        }

        let fieldDataErrors = this.state.fieldDataErrors;
        const { fieldData, tableName } = this.state;
        let languageTable = this.props.languageTable;
        if (typeof(languageTable) === 'undefined') {
            languageTable = tableName;
        }
        if (this.props.organizationId !== '' && typeof(this.props.organizationId) !== 'undefined' && this.state.tableName === 'users')
        {
            data["organizationId"] = this.props.organizationId;
        }
        fieldData.map((field) => {
            if (field.required === true && (data[field.fieldName] === '' || typeof(data[field.fieldName]) === 'undefined')) {
                errors=1;
                fieldDataErrors[field.fieldName] = languageText[languageCode].components[languageTable].fields[field.fieldName].requiredMessage;
                this.showToastr("error", "Missing Data - Can't Save", languageText[languageCode].components[languageTable].fields[field.fieldName].requiredMessage);
            }
            return null;
        });
        data["menteeId"] = this.props.menteeId;
        if (errors === 1) {
            this.setState({fieldDataErrors: fieldDataErrors, pageMode: prevPageMode, key: Math.floor(Math.random() * 101)});
        } else {
            this.props.saveRecord(this.state.tableName, id, data);
            this.setState({fieldDataErrors: []});
        }
    }
    exportFunction = () => {
        this.props.exportOverview(this.state.tableName);
    }
    changeValue = (name, e) => {
        let value = e.target.value;
        this.setState({[name]: value});
    };
    resetSearch = () => {
        this.setState({searchText: "", pageMode: "loading"});
        let searchText = "";
        let currentPage = this.state.currentPage;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        this.refreshSubFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    searchOverview = () => {
        this.setState({ pageMode: "loading" });
        let searchText = this.state.searchText;
        let currentPage = this.state.currentPage;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        this.refreshSubFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    closeFunction = () => {
        this.setState({ pageMode: "loading", fieldDataErrors: [] });
        this.refreshSubFunction();
    }
    changeRecordsPerPage = (e) => {
        this.setState({[e.target.name]: e.target.value, currentPage: 1});
        this.refreshSubFunction(1, e.target.value)
    };
    changeSort = (sortFieldName, sortDirection) => {
        if (sortDirection === "DESC") sortDirection = "ASC";
        else sortDirection = "DESC";
        this.setState({ pageMode: "loading", sortFieldName, sortDirection });
        this.refreshSubFunction(this.state.currentPage, this.state.recordsPerPage, sortFieldName, sortDirection);
    };
    showToastr = (type, title, message) => {
        const options = {
            timeOut: parseInt(this.state.timeOut),
            showCloseButton: this.state.showCloseButton,
            progressBar: this.state.progressBar,
            position: this.state.position
        };
        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            title,
            message,
            options
        );
    }

    render() {
        let pageMode = this.state.pageMode;
        let languageTable = this.props.languageTable;
        const { tableColumns, tableData, fieldData, fieldDataResults, sortFieldName, sortDirection, fieldDataErrors, singleEdit, currentPage, totalPages, totalRecords } = this.state;
        const { pageTitle, listTitle, listSubTitle, showExportData, addTitle, settings, tableName, recordsPerPage, searchText } = this.state;
        if (typeof(languageTable) === 'undefined') languageTable = tableName;
        let languageInfo = languageText[this.props.auth.user.languageCode];

        if (pageMode === "loading") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <Loader />
            </Container>);
        }
        if (pageMode === "list") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <DataList
                    title={listTitle}
                    addTitle={addTitle}
                    slogan={listSubTitle}
                    columns={tableColumns}
                    tableData={tableData}
                    tableName={tableName}
                    languageTable={languageTable}
                    languageInfo={languageInfo}
                    sortFieldName={sortFieldName}
                    sortDirection={sortDirection}
                    searchText={searchText}
                    showExportData={showExportData}
                    settings={settings}

                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    recordsPerPage={recordsPerPage}

                    resetSearch={this.resetSearch}
                    changeValue={this.changeValue}
                    searchOverview={this.searchOverview}
                    onUpdatePage={this.updatePage}
                    onAdd={this.addFunction}
                    onExport={this.exportFunction}
                    onChangeRecordsPerPage={this.changeRecordsPerPage}
                    changeSort={this.changeSort}
                    onEdit={this.editFunction}
                    onDelete={this.deleteConfirmFunction}
                    onArchive={this.archiveRecord}
                    key={this.state.key}
                />
            </Container>);
        }
        if (pageMode === "add" || pageMode === "view" || pageMode === "edit") {
            return (<Container fluid className="p-0">
                <h1 className="h3 mb-3">{pageTitle}</h1>
                <DataEdit
                    mode={pageMode}
                    singleEdit={singleEdit}
                    title={this.state[pageMode + "Title"]}
                    slogan={this.state[pageMode + "SubTitle"]}
                    tableName={tableName}
                    languageTable={languageTable}
                    languageInfo={languageInfo}
                    settings={settings}
                    dataFields={fieldData}
                    dataResults={fieldDataResults}
                    fieldDataResults={fieldDataResults}
                    dataErrors={fieldDataErrors}
                    onSave={this.saveFunction}
                    onCancel={this.closeFunction}
                    key={this.state.key}
                />
            </Container>);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getTable, getMenteeTable, getSubDataTable, archiveSubDataRecord, getRecord, getMentee, saveRecord, archiveRecord, deleteRecord, exportOverview} )(SubDataOverview);
