import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  landing as landingRoutes,
  dashboard as dashboardRoutes,
  page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import Page404 from "../chpages/errors/Page404";
import PropTypes from "prop-types";
import ScrollToTop from "../components/ScrollToTop";
import PrivateRoute from "../client/common/PrivateRoute";
import {connect} from "react-redux";

const childRoutes = (Layout, routes, auth) =>
    routes.map(({ children, isPrivate, path, component: Component, ...rest }, index) =>
    children ? (
      children.map(({ isPrivate, path, component: Component }, index2) => {
          // && auth.isAuthenticated !== true
        if (isPrivate === true ) {
          return (<PrivateRoute
              key={index2}
              path={path}
              auth={auth}
              exact
              {...rest}
              component={Component}
              layout={Layout}
          />);
        } else {
            return (<Route
                key={index2}
                path={path}
                auth={auth}
                exact
                {...rest}
                render={props => (<Layout>
                    <Component {...props} />
                </Layout>)}
            />);
        }
      })
    ) : (

        isPrivate === true ? (<PrivateRoute
            key={index}
            path={path}
            exact
            component={Component}
            layout={Layout}
            {...rest}
        />) : (<Route
            key={index}
            path={path}
            exact
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />)

    )
  );

const Routes = ({auth}) => {
    return (<Router>
        <ScrollToTop>
            <Switch>
                {childRoutes(LandingLayout, landingRoutes, auth)}
                {childRoutes(DashboardLayout, dashboardRoutes, auth)}
                {childRoutes(AuthLayout, pageRoutes, auth)}
                <Route
                    render={() => (
                        <AuthLayout>
                            <Page404 />
                        </AuthLayout>
                    )}
                />
            </Switch>
        </ScrollToTop>
    </Router>);
}

Routes.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Routes);