import React, {Component} from "react";
import DataOverview from "../chcomponents/DataOverview";
import {languageText} from "../config"; // Import css

const tableName = "configLevelOfCare";
class LevelOfCare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    text: "Status",
                    type: "bool",
                    sort: true
                },
                {
                    dataField: "name",
                    text: "Name",
                    sort: true
                },
                {
                    dataField: "tools",
                    text: "Tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    displayName: "Name",
                    placeholder: "name",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Name is required!"
                },
                {
                    fieldName: "active",
                    displayName: "Active",
                    placeholder: "",
                    fieldType: "toggle",
                    colSize: 2
                },
            ],
            fieldDataDefault: {
                name: '',
                active: 1,
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            languageInfo={languageText[languageCode]}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default LevelOfCare;
