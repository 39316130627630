import React, {Component} from "react";
import {languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";

const tableName = "eLearning";

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "organization",
                    sort: false
                },
                {
                    dataField: "name",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                },
                {
                    fieldName: "active",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "description",
                    fieldType: "editor",
                    colSize: 1
                },
                {
                    fieldName: "learningUrl",
                    fieldType: "text",
                    colSize: 1
                },
                {
                    fieldName: "organizationId",
                    fieldType: "hidden",
                    colSize: 2
                }
            ],
        }
    }
    render() {
        const { tableColumns, fieldData } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            singleEdit={false}
            tableName={tableName}
            languageTable={tableName}
            recordsPerPage={25}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            languageInfo={languageText[languageCode]}
            tableColumns={tableColumns}
            fieldData={fieldData}
        />);
    }
}
export default Jobs;
