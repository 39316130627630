import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList} from "../client/actions/apiActions";

class MenteePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            pageMode: "loading",
            tableColumns : [],
            fieldData: [
                {
                    fieldName: "password",
                    fieldType: "password",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Entering a password is required!"
                },
                {
                    fieldName: "passwordConfirm",
                    fieldType: "password",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Confirming your password is required!"
                }
            ],
            fieldDataDefault: {},
            languageCode: ''
        }
    }
    componentDidMount() {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        this.props.getList('menteeGoals');
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({menteeId, languageCode, pathName});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.data.menteeGoals) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'menteeGoalId') {
                        fieldData[index].dropdownData = this.props.data.menteeGoals;
                    }
                    return null;
                });
                this.setState({pageMode: 'data', fieldData});
            }
        }
    }

    render() {
        const { pageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (pageMode === 'loading') {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="mentee"
                languageTable="menteeUserPassword"
                singleEdit={true}
                settings={{'showExport': false, 'showClone': false, 'showSearch': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(MenteePassword);
