import React, { Component, Fragment} from "react";
import { Button, Card, CardHeader, CardTitle } from "reactstrap";
import { Save as SaveIcon, XCircle as CancelIcon } from "react-feather";
import CardBody from "reactstrap/es/CardBody";
import CardFooter from "reactstrap/es/CardFooter";
import FormInput from "./form/FormInput";
import Loader from "../components/Loader";
import AdddressGoogleMap from "./google-maps/AddressGoogleMap";
import { languageText } from "../config";
import { getUserRoles } from "../client/actions/apiActions"
import {connect} from "react-redux";

class DataEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFields: [],
            dataResults: [],
            loading: true
        }
        this.selectedCheckboxes = new Set();
    }
    componentDidMount() {
        let dataResults = this.props.dataResults;
        if (typeof(dataResults.languageList) !== "undefined") {
            let languageList = dataResults.languageList.split(",");
            languageList.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes.add(options);
                }
                return {...options};
            });
        }

        // CHECKBOXES
        if (typeof(dataResults.adverseExperiences) !== "undefined") {
            let adverseExperiences = dataResults.adverseExperiences.split(",");
            if (typeof(this.selectedCheckboxes["adverseExperiences"]) === 'undefined')
            {
                this.selectedCheckboxes["adverseExperiences"] = new Set();
            }
            adverseExperiences.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["adverseExperiences"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.levelOfCare) !== "undefined") {
            let levelOfCare = dataResults.levelOfCare.split(",");
            if (typeof(this.selectedCheckboxes["levelOfCare"]) === 'undefined')
            {
                this.selectedCheckboxes["levelOfCare"] = new Set();
            }
            levelOfCare.map((options) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["levelOfCare"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.traumaInformStatus) !== "undefined") {
            let traumaInformStatus = dataResults.traumaInformStatus.split(",");
            if (typeof(this.selectedCheckboxes["traumaInformStatus"]) === 'undefined')
            {
                this.selectedCheckboxes["traumaInformStatus"] = new Set();
            }
            traumaInformStatus.map((options) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["traumaInformStatus"].add(options);
                }
                return {...options};
            });
            console.log("this.selectedCheckboxes", dataResults.traumaInformStatus, this.selectedCheckboxes);
        }
        if (typeof(dataResults.offerTypes) !== "undefined") {
            let offerTypes = dataResults.offerTypes.split(",");
            if (typeof(this.selectedCheckboxes["offerTypes"]) === 'undefined')
            {
                this.selectedCheckboxes["offerTypes"] = new Set();
            }
            offerTypes.map((options) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["offerTypes"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.programStatus) !== "undefined") {
            let programStatus = dataResults.programStatus.split(",");
            if (typeof(this.selectedCheckboxes["programStatus"]) === 'undefined')
            {
                this.selectedCheckboxes["programStatus"] = new Set();
            }
            programStatus.map((options) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["programStatus"].add(options);
                }
                return {...options};
            });
        }
        if (typeof(dataResults.userRole) !== 'undefined' && this.props.tableName === 'users') {
            let userRole = dataResults.userRole.split(",");
            if (typeof(this.selectedCheckboxes["userRole"]) === 'undefined')
            {
                this.selectedCheckboxes["userRole"] = new Set();
            }
            userRole.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["userRole"].add(options);
                }
                return {...options};
            });
            this.props.getUserRoles(this.props.organizationId);
        }
        if (typeof(dataResults.tablesCanAccess) !== 'undefined' && this.props.tableName === 'userRoles') {
            let tablesCanAccess = dataResults.tablesCanAccess.split(",");
            if (typeof(this.selectedCheckboxes["tablesCanAccess"]) === 'undefined')
            {
                this.selectedCheckboxes["tablesCanAccess"] = new Set();
            }
            tablesCanAccess.map((options, index) => {
                if (options !== '' && typeof(options) !== 'undefined') {
                    this.selectedCheckboxes["tablesCanAccess"].add(options);
                }
                return {...options};
            });
        }
        if (this.props.tableName === 'mentee' && this.props.languageTable === 'menteeUserPassword')
        {
            dataResults = {
                id: this.props.fieldDataResults.id
            };
        }


        this.setState({
            dataResults: dataResults,
            loading: false
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (typeof(this.props.data.userRoles) !== 'undefined')
            {
                let dataFields = this.props.dataFields;
                dataFields.map((field, index) =>
                {
                    if (field.fieldName === 'userRole')
                    {
                        dataFields[index]["valueOptions"] = this.props.data.userRoles;
                    }
                    return null;
                });
                this.setState({ dataFields: dataFields });
            }
        }
    }

    onChange = (type, e, field) => {
        let dataResults = this.state.dataResults;
        let tmpValue = '';
        if (type === 'text')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'phone')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'email')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'textarea')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'toggle')
        {
            if (e.target.checked === true) dataResults[field.fieldName] = '1';
            if (e.target.checked === false) dataResults[field.fieldName] = '0';
        }
        else if (type === 'image')
        {
            dataResults[field.fieldName] = e;
        }
        else if (type === 'editor')
        {
            dataResults[field.fieldName] = e; // e.target.getContent();
        }
        else if (type === 'select' || type === 'dropdown')
        {
            dataResults[e.target.name] = e.target.value;
        }
        else if (type === 'password')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'checkbox')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else if (type === 'radio')
        {
            dataResults[field.fieldName] = e.target.value;
        }
        else
        {
            dataResults[field.fieldName] = e.target.value;
        }

        if (typeof(field) !== 'undefined')
        {
            if (typeof(field.callBackFunction) !== "undefined")
            {
                this.props[field.callBackFunction](tmpValue);
            }
        }

        this.setState({dataResults: dataResults});
    };

    onButtonCancel = () => {
        for (const checkbox of this.selectedCheckboxes) {
            if (this.selectedCheckboxes.has(checkbox)) {
                this.selectedCheckboxes.delete(checkbox);
            }
        }
        this.props.onCancel();
    };
    handleCheckboxChange = (name, label) => {
        if (typeof(this.selectedCheckboxes[name]) === 'undefined') this.selectedCheckboxes[name] = new Set();
        if (this.selectedCheckboxes[name].has(label)) {
            this.selectedCheckboxes[name].delete(label);
        } else {
            this.selectedCheckboxes[name].add(label);
        }

        let checkedBoxes = ',';
        for (const checkbox of this.selectedCheckboxes[name]) {
            checkedBoxes += checkbox + ",";
        }
        this.setState({
            dataResults: {...this.state.dataResults, [name]: checkedBoxes}
        })
    };
    changeValue = (name, value) => {
        let dataResults = this.state.dataResults;
        dataResults[name] = value;
        this.setState({dataResults: dataResults});
    };
    deactivateMentee = () => {
        console.log("deactivateMentee clicked", this.props);
    }
    render() {
        let dataFields = this.state.dataFields;
        if (dataFields.length === 0) {
            dataFields = this.props.dataFields;
        }

        const { dataErrors, title, slogan, settings } = this.props;
        const { loading, dataResults } = this.state;
        const {  tableName } = this.props;
        const {  languageCode } = this.props.auth.user;
        let languageTable = this.props.languageTable;
        if (languageTable !== '') {

        } else {
            languageTable = tableName;
        }
        if (loading === true)
        {
            return (<Loader/>);
        }
        else
        {
            if (settings.showCard === false)
            {
                return (<Fragment>
                    <h5>{title}</h5>
                    <h6 className="card-subtitle text-muted">{slogan}</h6>
                    <div className="container">
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                        <div className="row align-items-end">
                            {dataFields.map((field, index) => {
                                if (typeof(languageText[languageCode].components[languageTable].fields[field.fieldName]) === "undefined") {
                                    console.log("Field not found in language file - Field Name: ", field.fieldName, languageTable, languageCode);
                                    return null;
                                } else {
                                    let label = languageText[languageCode].components[languageTable].fields[field.fieldName].displayName;
                                    let placeholder = languageText[languageCode].components[languageTable].fields[field.fieldName].placeholder;
                                    return (<DataFields
                                        key={index}
                                        id={dataResults.id}
                                        field={field}
                                        fieldType={field.fieldType}
                                        fieldName={field.fieldName}
                                        fieldLabel={label}
                                        fieldPlaceHolder={placeholder}
                                        tableName={this.props.tableName}
                                        value={dataResults[field.fieldName]}
                                        error={dataErrors[field.fieldName]}
                                        onChange={this.onChange}
                                        changeValue={this.changeValue}
                                        handleCheckboxChange={this.handleCheckboxChange}
                                    />);
                                }
                            })}
                        </div>
                        {typeof(dataResults.addressLatitude) !== 'undefined' && dataResults.addressLatitude !== '' && dataResults.addressLongitude !== '' && <div className="row">
                            <AdddressGoogleMap
                                lat={dataResults.addressLatitude}
                                lng={dataResults.addressLongitude}
                                radius={dataResults.addressRadius}
                            />
                            <div className="col-12 small">
                                Latitude: {dataResults.addressLatitude},
                                Longitude: {dataResults.addressLongitude},
                                Address Mapped: {dataResults.address}
                            </div>
                        </div>}
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                    </div>
                </Fragment>);
            }
            else
            {
                return (<Card>
                    <CardHeader>
                        <CardTitle tag="h5">{title}</CardTitle>
                        <h6 className="card-subtitle text-muted">{slogan}</h6>
                        <DataButtons {...this.props} dataResults={dataResults} onButtonCancel={this.onButtonCancel} />
                    </CardHeader>
                    <CardBody>
                        <div className="container">
                            <div className="row align-items-end">
                                {dataFields.map((field, index) => {
                                    if (typeof(languageText[languageCode].components[languageTable].fields[field.fieldName]) === "undefined") {
                                        console.log("Field not found in language file - Field Name: ", field.fieldName, languageTable, languageCode, languageText[languageCode].components[languageTable]);
                                        return null;
                                    } else {
                                        let label = languageText[languageCode].components[languageTable].fields[field.fieldName].displayName;
                                        let placeholder = languageText[languageCode].components[languageTable].fields[field.fieldName].placeholder;
                                        return (<DataFields
                                            key={index}
                                            id={dataResults.id}
                                            field={field}
                                            fieldType={field.fieldType}
                                            fieldName={field.fieldName}
                                            fieldLabel={label}
                                            fieldPlaceHolder={placeholder}
                                            tableName={this.props.tableName}
                                            value={dataResults[field.fieldName]}
                                            error={dataErrors[field.fieldName]}
                                            onChange={this.onChange}
                                            changeValue={this.changeValue}
                                            handleCheckboxChange={this.handleCheckboxChange}
                                        />);
                                    }
                                })}
                            </div>
                            {typeof(dataResults.addressLatitude) !== 'undefined' && dataResults.addressLatitude !== '' && dataResults.addressLongitude !== '' && <div className="row">
                                <AdddressGoogleMap
                                    lat={dataResults.addressLatitude}
                                    lng={dataResults.addressLongitude}
                                    radius={dataResults.addressRadius}
                                />
                                <div className="col-12 small">
                                    Latitude: {dataResults.addressLatitude},
                                    Longitude: {dataResults.addressLongitude},
                                    Address Mapped: {dataResults.address}
                                </div>
                            </div>}
                        </div>
                    </CardBody>
                    <CardFooter>
                        <DataButtons {...this.props} dataResults={dataResults} nButtonCancel={this.onButtonCancel} />
                    </CardFooter>
                </Card>);
            }
        }
    }
}
class DataFields extends Component {
    render() {
        const { field, fieldName, fieldType, fieldLabel, handleCheckboxChange, fieldPlaceHolder, value, onChange, changeValue, error, tableName, id } = this.props;
        return (
            <FormInput
                id={id}
                field={field}
                type={fieldType}
                name={fieldName}
                defaultValue={field.defaultValue}
                dropdown={field.dropdownData}
                valueOptions={field.valueOptions}
                checkboxData={field.checkboxData}
                colSize={field.colSize}
                value={value}
                changeValue={changeValue}
                tableName={tableName}
                onChange={onChange}
                error={error}
                label={fieldLabel}
                handleCheckboxChange={handleCheckboxChange}
                placeholder={fieldPlaceHolder}
            />
        );
    }
}
class DataButtons extends Component {
    render () {
        const {  dataResults } = this.props;
        const {  languageCode } = this.props.auth.user;
        return (<div className="container my-3">
            {this.props.singleEdit !== true && <div className="row">
                <div className="col-6">
                    <Button onClick={() => this.props.onSave(dataResults["id"], dataResults)} color="primary">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.save}
                    </Button>
                    {this.props.settings.showClone !== false && dataResults.id !== 0 && <Button onClick={() => this.props.onSave(0, dataResults)} color="secondary" className="ml-1">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.clone}
                    </Button>}
                </div>
                <div className="col-6 text-right">
                    <Button onClick={() => this.props.onCancel()} color="warning">
                        <CancelIcon size={18} />{' '}
                        {languageText[languageCode].buttons.cancel}
                    </Button>
                </div>
            </div>}
            {this.props.singleEdit === true && <div className="row">
                <div className="col-12">
                    <Button onClick={() => this.props.onSave(dataResults["id"], dataResults)} color="primary">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.save}
                    </Button>
                    {this.props.settings.showClone !== false && <Button onClick={() => this.props.onSave(0, dataResults)} color="primary" className="ml-1">
                        <SaveIcon size={18} />{' '}
                        {languageText[languageCode].buttons.clone}
                    </Button>}
                </div>
            </div>}
        </div>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});

export default connect(mapStateToProps, {getUserRoles})(DataEdit);
