import React, {Fragment} from 'react';
import classnames from 'classnames';
import ShowInputError from "./ShowInputError";

const FormInputTextArea = ({name, value, label, colSize, field, placeholder, type, valueOptions, helpText, onChange, error}) => {
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    return (
        <div className={`col-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    <label htmlFor={name} className={classnames('col-sm-12 m-0 pt-2 pb-2', { 'is-invalid': error})}>
                        {label}
                        {field.required === true && <Fragment>{' '} <small className="text-danger">* Required field</small></Fragment>}
                    </label>
                    <div className="col-sm-12">
                        <textarea
                            name={name}
                            id={name}
                            rows="5"
                            className={classnames('form-control form-control-sm', { 'is-invalid': error})}
                            onChange={(e) => onChange(type, e, field)}
                            aria-describedby={name+"Help"}
                            placeholder={placeholder}
                            value={value}
                        />
                    </div>
                    {helpText && <small id={name + "Help"} className="form-text text-muted">{helpText}</small>}
                    {error && <ShowInputError error={error} />}
                </div>
            </div>
        </div>
    )
};

export default FormInputTextArea