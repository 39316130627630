import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList} from "../client/actions/apiActions";

class MenteeTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            subPageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: true,
                },
                {
                    dataField: "menteeTaskId",
                    sort: true
                },
                {
                    dataField: "startDate",
                    sort: true
                },
                {
                    dataField: "endDate",
                    sort: true
                },
                {
                    dataField: "points",
                    sort: true
                },
                {
                    dataField: "completed",
                    type: "bool",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "menteeTaskId",
                    fieldType: "dropdown",
                    colSize: 3
                },
                {
                    fieldName: "active",
                    fieldType: "toggle",
                    colSize: 3
                },

                {
                    fieldName: "completed",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "startDate",
                    fieldType: "date",
                    colSize: 3,
                    required: true,
                    requiredMessage: "Start Date is required!"
                },
                {
                    fieldName: "endDate",
                    fieldType: "date",
                    colSize: 3,
                    required: true,
                    requiredMessage: "End Date is required!"
                },
                {
                    fieldName: "points",
                    fieldType: "number",
                    colSize: 3
                },
                {
                    fieldName: "description",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "dataNotes",
                    fieldType: "linkedTable",
                    linkedTableInfo: {
                        columns: [
                            'dateEntered',
                            'note'
                        ]
                    },
                    colSize: 1
                },
                {
                    fieldName: "dataFiles",
                    fieldType: "linkedTable",
                    linkedTableInfo: {
                        columns: [
                            'dateCreated',
                            'uploadedBy',
                            'file'
                        ]
                    },
                    colSize: 1
                },
            ],
            fieldDataDefault: {
                name: '',
                startDate: '',
                endDate: '',
                description: '',
                notes: ''
            },
            languageCode: ''
        }
    }
    componentDidMount() {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({ menteeId, languageCode, pathName });
        this.props.getList('menteeTasks');
    }
    componentWillUnmount() {
        this.setState({subPageMode: "loading"});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.data.menteeTasks) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'menteeTaskId') {
                        fieldData[index].dropdownData = this.props.data.menteeTasks;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
        }
    }
    render() {
        const { subPageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (subPageMode === 'loading')
        {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="menteeUserTasks"
                languageTable="Tasks"
                settings={{'showExport': false, 'showClone': false, 'showSearch': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(MenteeTasks);
