import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormInput from "./form/FormInput";
import { ethnicity } from "../config";
import React from "react";

const DataAdvancedExport = ({
  closeAdvExport,
  advExportMentees,
  advExportReport,
  advExports,
  checkedOptions,
  languageInfo,
  onChange,
  handleCheckboxChange,
  startDate,
  endDate,
}) => {
  console.log("advanced export");

  return (
    <div className="card">
      <div className="card-header text-right">
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => closeAdvExport()}
        >
          <FontAwesomeIcon icon={faTimes} /> {languageInfo.buttons["close"]}
        </button>
      </div>
      <div className="card-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>{languageInfo.buttons["exportData"]}</h3>
            </div>
            <div className="col-12">
              <button
                className="btn btn-sm btn-secondary mr-1"
                onClick={() => advExportMentees()}
              >
                {languageInfo.buttons["exportDataList"]}
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => advExportReport()}
              >
                {languageInfo.buttons["exportDataReport"]}
              </button>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="container p-0">
                <div
                  className="form-group row border-bottom pb-2 align-content-center align-middle justify-content-center"
                  style={{ height: "70px" }}
                >
                  <label className="col-12 align-content-center align-middle justify-content-center">
                    {languageInfo.buttons["exportDateRange"]}
                  </label>
                </div>
              </div>
            </div>
            <FormInput
              type={"date"}
              id={"startDate"}
              name={"startDate"}
              label={languageInfo.buttons["exportStartDate"]}
              helpText=""
              field={{ required: false }}
              value={startDate}
              colSize={3}
              onChange={onChange}
              error={""}
            />
            <FormInput
              type={"date"}
              id={"endDate"}
              name={"endDate"}
              label={languageInfo.buttons["exportEndDate"]}
              helpText=""
              field={{ required: false }}
              value={endDate}
              colSize={3}
              onChange={onChange}
              error={""}
            />
          </div>
          <div className="row">
            <FormInput
              type={"fancyCheckbox"}
              id={"ethnicity"}
              name={"ethnicity"}
              label={languageInfo.sideBar["ethnicity"]}
              helpText=""
              defaultValue={false}
              field={{ required: false }}
              value={
                typeof checkedOptions["ethnicity"] !== "undefined"
                  ? checkedOptions["ethnicity"]
                  : null
              }
              colSize={1}
              checkboxData={ethnicity}
              handleCheckboxChange={handleCheckboxChange}
              error={""}
            />
            {Object.keys(advExports).length > 0 && (
              <>
                {Object.keys(advExports).map((filter, index) => {
                  let filterName = filter.replace("config", "");
                  return (
                    <FormInput
                      key={index}
                      type={"fancyCheckbox"}
                      id={filterName}
                      name={filterName}
                      label={
                        languageInfo.components["config" + filterName][
                          "pageTitle"
                        ]
                      }
                      helpText=""
                      defaultValue={false}
                      field={{ required: false }}
                      value={
                        typeof checkedOptions[filterName] !== "undefined"
                          ? checkedOptions[filterName]
                          : null
                      }
                      colSize={1}
                      checkboxData={advExports[filter]}
                      handleCheckboxChange={handleCheckboxChange}
                      error={""}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-sm btn-secondary mr-1"
                onClick={() => advExportMentees()}
              >
                {languageInfo.buttons["exportDataList"]}
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => advExportReport()}
              >
                {languageInfo.buttons["exportDataReport"]}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataAdvancedExport;
