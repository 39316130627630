import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList} from "../client/actions/apiActions";

class MenteeNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "date",
                    sort: true
                },
                {
                    dataField: "noteTitle",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "noteTitle",
                    fieldType: "text",
                    colSize: 1,
                    required: true,
                    requiredMessage: "Title is required!"
                },
                {
                    fieldName: "note",
                    fieldType: "simpleEditor",
                    colSize: 1
                },
            ],
            fieldDataDefault: {
                name: '',
                startDate: '',
                endDate: '',
                notes: ''
            },
            languageCode: ''
        }
    }
    componentDidMount() {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({menteeId, languageCode, pathName, pageMode: 'data'});
    }

    render() {
        const { pageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (pageMode === 'loading') {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="menteeUserNotes"
                languageTable="Notes"
                settings={{'showExport': false, 'showClone': false, 'showSearch': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(MenteeNotes);
