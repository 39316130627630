import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList, getMentee} from "../client/actions/apiActions";
import { menteeDataFields } from "../config";

class MenteeGoals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "image",
                    type: "image",
                    sort: true
                },
                {
                    dataField: "firstName",
                    sort: true
                },
                {
                    dataField: "lastName",
                    sort: true
                },
                {
                    dataField: "email",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: menteeDataFields,
            fieldDataDefault: {
                name: '',
                active: true,
                cellPhone: '',
                workPhone: '',
                faxPhone: '',
                email: '',
                website: '',
                notes: '',
                timezones: '',
                agencyType: '',
                serviceArea: '',
                address: '',
                addressLatitude: '',
                addressLongitude: '',
                password:'',
                passwordConfirm: ''
            },
            languageCode: ''
        }
    }
    componentDidMount()
    {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        this.props.getList('configGenders', ['configSexualOrientation']);
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({menteeId, languageCode, pathName});
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps !== this.props)
        {
            if (typeof(this.props.data.tableName) !== 'undefined')
            {
                if (this.props.data.tableName === "configGenders")
                {
                    let fieldData = this.state.fieldData;
                    fieldData.map((data, index) => {
                        if (data.fieldName === 'gender') {
                            fieldData[index].dropdownData = this.props.data.configGenders;
                        }
                        if (data.fieldName === 'sexualOrientation') {
                            fieldData[index].dropdownData = this.props.data.configSexualOrientation;
                        }
                        return null;
                    });
                    this.setState({pageMode: 'data', fieldData});
                }
            }
        }
    }

    render() {
        const { pageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (pageMode === 'loading')
        {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="mentee"
                languageTable="mentee"
                singleEdit={true}
                settings={{'showExport': false, 'showClone': false, 'showSearch': false, 'showCard': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList, getMentee} )(MenteeGoals);
