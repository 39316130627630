import {GET_LIST, GET_DATA, GET_CHAT_MESSAGES} from "../actions/types";

const initialState = {
    data: {},
    dataList: {},
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DATA:
            return {
                ...state,
                data: action.payload
            };

        case GET_LIST:
            return {
                ...state,
                dataList: action.payload
            };


        case GET_CHAT_MESSAGES:
            return {
                ...state,
                data: action.payload
            };

        default:
            return state;
    }
}