import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { loadDashboard } from "../client/actions/apiActions";
import { MessageSquare as ChatIcon, Eye as ViewIcon } from "react-feather";
import { Link } from "react-router-dom";
import { ApiURL, languageText } from "../config";
import Modal from "../chcomponents/Modal";
import FormInput from "../chcomponents/form/FormInput";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMode: "loading",
      chats: [],
      notifications: [],
      urgentMentees: [],
      mentees: [],
      goals: [],
      tasks: [],
      challenges: [],
    };
  }

  componentDidMount() {
    this.props.loadDashboard();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      this.setState({
        chats: this.props.data.chats,
        notifications: this.props.data.dashboardNotifications,
        mentees: this.props.data.mentees,
        urgentMentees: this.props.data.urgentMentees,
        goals: this.props.data.goals,
        tasks: this.props.data.tasks,
        challenges: this.props.data.challenges,
      });
    }
  }

  showExportModal = () => {
    this.setState({ showExportModal: true });
  };

  hideExportModal = () => {
    this.setState({ showExportModal: false });
  };

  onExport = () => {
    window.open(ApiURL + "/export/gtc/" + this.state.exportGTC);
    this.hideExportModal();
  };

  onChange = (type, e, field) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      chats,
      notifications,
      urgentMentees,
      mentees,
      goals,
      tasks,
      challenges,
      showExportModal,
    } = this.state;
    let languageInfo = languageText[this.props.auth.user.languageCode];
    return (
      <div className="container">
        {(this.props.auth.user.userRole === "1" ||
          this.props.auth.user.userRole === 1 ||
          this.props.auth.user.userRole === 2 ||
          this.props.auth.user.userRole === "2") && (
          <div className="row">
            <div className="col-12 mb-4">
              <button
                className="btn btn-sm btn-primary"
                onClick={this.showExportModal}
              >
                Export G,T,C
              </button>
              {showExportModal && (
                <Modal
                  title="Start New Chat"
                  show={showExportModal}
                  handleClose={this.hideExportModal}
                  name={"modalExportGTC"}
                  saveButton={"Export"}
                  onSave={() => this.onExport()}
                >
                  <FormInput
                    type="radio"
                    name="exportGTC"
                    id="exportGTC"
                    label="Choose export type"
                    field={{}}
                    onChange={this.onChange}
                    valueOptions={[
                      { name: "Completed Goals", value: "goals" },
                      { name: "Completed Tasks", value: "tasks" },
                      { name: "Completed Challenges", value: "challenges" },
                      { name: "ALL", value: "all" },
                    ]}
                  />
                </Modal>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-6">
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title1}</h3>
                {urgentMentees.map((mentee, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{mentee.date}</div>
                      <div className="col-6">{mentee.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/manage/${mentee.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title2}</h3>
                {mentees.map((mentee, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{mentee.date}</div>
                      <div className="col-6">{mentee.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/manage/${mentee.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title3}</h3>
                {notifications.map((notification, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{notification.date}</div>
                      <div className="col-6">
                        {notification.notificationSubject}{" "}
                        {notification.notificationMessage}
                      </div>
                      <div className="col-3 text-right">
                        <Link
                          to="/profile-notifications"
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </div>
          <div className="col-12 col-sm-6">
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title4}</h3>
                {chats.map((chat, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{chat.date}</div>
                      <div className="col-6">{chat.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/chat/${chat.userID}`}
                          className="btn btn-sm btn-success mr-1"
                        >
                          <ChatIcon size={18} />
                        </Link>
                        <Link
                          to={`/mentee/manage/${chat.userID}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title5}</h3>
                {challenges.map((challenge, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{challenge.date}</div>
                      <div className="col-3">{challenge.menteeName}</div>
                      <div className="col-3">{challenge.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/mentee-challenges/${challenge.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title6}</h3>
                {goals.map((goal, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{goal.date}</div>
                      <div className="col-3">{goal.menteeName}</div>
                      <div className="col-3">{goal.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/mentee-goals/${goal.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
            <Card className="border dashboardCard">
              <CardBody>
                <h3>{languageInfo.components.dashboard.title7}</h3>
                {tasks.map((task, index) => {
                  return (
                    <div key={index} className="row text-sm mb-1">
                      <div className="col-3">{task.date}</div>
                      <div className="col-3">{task.menteeName}</div>
                      <div className="col-3">{task.name}</div>
                      <div className="col-3 text-right">
                        <Link
                          to={`/mentee/mentee-tasks/${task.id}`}
                          className="btn btn-sm btn-primary"
                        >
                          <ViewIcon size={18} />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, { loadDashboard })(Dashboard);
