import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    PAGE_ERROR,
    SAVE_FORGOT_PASS,
    FORGOT_PASS_SUCCESS,
    CHECK_VERIFICATION_CODE
} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    isLoading: false,
    user: null
};

export default function(state = initialState, action) {
    switch(action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case USER_LOADED:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };

        case AUTH_ERROR:
        case LOGIN_FAIL:
        case PAGE_ERROR:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            };

        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            };

        case FORGOT_PASS_SUCCESS:
        case SAVE_FORGOT_PASS:
        case CHECK_VERIFICATION_CODE:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: false,
                isLoading: false,
                user: action.payload
            };

        default:
            return state;
    }
}