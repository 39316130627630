import React, {Fragment, Component} from "react";
import { Button, Card, CardHeader, CardTitle, Table} from "reactstrap";
import {
    ChevronDown as SortDown, Download as DownloadIcon, Search as SearchIcon, Users as UsersIcons,
    XOctagon as ResetIcon,ChevronUp as SortUp, Edit as EditIcon, Trash2 as DeleteIcon,
    PlusSquare as AddIcon, CheckCircle as ActiveIcon, XCircle as InActiveIcon, EyeOff as ArchiveIcon,
    Eye as UnArchiveIcon, Mail as EmailIcon, MessageSquare as ChatIcon, Eye as ViewIcon
} from "react-feather";
import {languageText} from "../config";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import { archiveSubDataRecord } from "../client/actions/apiActions";

let associationId = 0;
class DataList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataMode: 'loading'
        };
    }

    componentDidMount() {
        this.setState({ dataMode: 'data' });
        associationId = this.props.auth.user.associationId;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data.dataType === "archiveRecord")
            {
                console.log("archive Record");
            }
        }
    }

    onArchive = (id) => {
        this.props.archiveSubDataRecord(this.props.tableName, id);
    }

    render() {
        const { tableData, settings, searchText, tableName, columns, title, sortFieldName, searchKeyPress, resetSearch,
            sortDirection, slogan, onAdd, onViewsUsers, changeValue, searchOverview, onExport, onAdvExport, recordsPerPage, addTitle,
            onChangeRecordsPerPage, changeSort, onEdit, onDelete, onUpdatePage, currentPage, totalPages, totalRecords, organizationId, userPermissions, languageInfo } = this.props;
        const {  languageCode } = this.props.auth.user;
        let hasStatus = false;

        if (this.state.dataMode === 'data') {
            if (settings.showCard === false) {
                return (<Fragment>
                    <h6 className="card-subtitle text-muted">{slogan}</h6><br/>
                    <div className="row align-items-center">
                        <DataListAddButtons
                            onExport={onExport}
                            onAdvExport={onAdvExport}
                            onAdd={onAdd}
                            addTitle={addTitle}
                            settings={settings}
                            userPermissions={userPermissions}
                            languageInfo={languageInfo}
                        />
                        <SearchFunction
                            settings={settings}
                            searchText={searchText}
                            searchOverview={searchOverview}
                            resetSearch={resetSearch}
                            changeValue={changeValue}
                            searchKeyPress={searchKeyPress}
                            languageInfo={languageInfo}
                        />
                    </div>
                    <DataListTable
                        columns={columns}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onUpdatePage={onUpdatePage}
                        recordsPerPage={recordsPerPage}
                        sortDirection={sortDirection}
                        sortFieldName={sortFieldName}
                        hasStatus={hasStatus}
                        languageCode={languageCode}
                        languageInfo={languageInfo}
                        tableData={tableData}
                        tableName={tableName}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onArchive={this.onArchive}
                        changeSort={changeSort}
                        onChangeRecordsPerPage={onChangeRecordsPerPage}
                        totalRecords={totalRecords}
                        onViewsUsers={onViewsUsers}
                        userPermissions={userPermissions}
                    />
                </Fragment>);
            } else {
                return (<Card>
                    <CardHeader>
                        {tableName === 'users' && organizationId !== '' && typeof (organizationId) !== 'undefined' &&
                        <div className="row mb-2">
                            <div className="col-6">
                                <CardTitle tag="h5">{title}</CardTitle>
                                <h6 className="card-subtitle text-muted">{slogan}</h6>
                            </div>
                        </div>}
                        {tableName !== 'users' && <div className="row mb-2">
                            <div className="col-12">
                                <CardTitle tag="h5">{title}</CardTitle>
                                <h6 className="card-subtitle text-muted">{slogan}</h6>
                            </div>
                        </div>}
                        <div className="row align-items-center">
                            <DataListAddButtons
                                onExport={onExport}
                                onAdvExport={onAdvExport}
                                onAdd={onAdd}
                                addTitle={addTitle}
                                settings={settings}
                                tableName={tableName}
                                userPermissions={userPermissions}
                                languageInfo={languageInfo}
                            />
                            <SearchFunction
                                settings={settings}
                                searchText={searchText}
                                searchOverview={searchOverview}
                                resetSearch={resetSearch}
                                changeValue={changeValue}
                                searchKeyPress={searchKeyPress}
                                languageInfo={languageInfo}
                            />
                        </div>
                    </CardHeader>
                    <DataListTable
                        columns={columns}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onUpdatePage={onUpdatePage}
                        recordsPerPage={recordsPerPage}
                        sortDirection={sortDirection}
                        sortFieldName={sortFieldName}
                        hasStatus={hasStatus}
                        languageCode={languageCode}
                        languageInfo={languageInfo}
                        tableData={tableData}
                        tableName={tableName}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onArchive={this.onArchive}
                        changeSort={changeSort}
                        onChangeRecordsPerPage={onChangeRecordsPerPage}
                        totalRecords={totalRecords}
                        onViewsUsers={onViewsUsers}
                        userPermissions={userPermissions}
                    />
                </Card>);
            }
        } else {
            return (<div>&nbsp;</div>);
        }
    }
}
const DataListTable = ({
   columns, currentPage, totalPages, onUpdatePage, recordsPerPage, sortDirection, sortFieldName, hasStatus,
   languageCode, tableData, tableName, onEdit, onDelete, onArchive, changeSort,
   onChangeRecordsPerPage, totalRecords, onViewsUsers, userPermissions, languageInfo
}) => {
    return (<div className="table-responsive">
        <Table striped hover size="sm">
            <thead>
                <DataListPages
                    columns={columns}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onUpdatePage={onUpdatePage}
                    recordsPerPage={recordsPerPage}
                    onChangeRecordsPerPage={onChangeRecordsPerPage}
                    languageInfo={languageInfo}
                />
            </thead>
            <thead className="table-dark">
                <tr>
                    {columns && <DataListColumns
                        columns={columns}
                        sortDirection={sortDirection}
                        sortFieldName={sortFieldName}
                        changeSort={changeSort}
                        hasStatus={hasStatus}
                        languageCode={languageCode}
                        languageInfo={languageInfo}
                        tableName={tableName}
                    />}
                </tr>
            </thead>
            <tbody>
                {tableData && <DataListData
                    tableData={tableData}
                    columns={columns}
                    tableName={tableName}
                    onViewsUsers={onViewsUsers}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    hasStatus={hasStatus}
                    onArchive={onArchive}
                    languageCode={languageCode}
                    userPermissions={userPermissions}
                    languageInfo={languageInfo}
                />}
            </tbody>
            <tfoot>
            <tr><td colSpan={columns.length} /></tr>
            <DataListPages
                columns={columns}
                currentPage={currentPage}
                totalPages={totalPages}
                onUpdatePage={onUpdatePage}
                recordsPerPage={recordsPerPage}
                onChangeRecordsPerPage={onChangeRecordsPerPage}
                languageInfo={languageInfo}
            />
            <tr>
                <td colSpan={columns.length}>
                    {languageInfo && languageInfo.buttons && <>{languageInfo.buttons["totalPages"]}</>} : {totalPages} -
                    {languageInfo && languageInfo.buttons && <>{languageInfo.buttons["totalRecords"]}</>} : {totalRecords}
                </td>
            </tr>
            </tfoot>
        </Table>
    </div>);
}

const DataListAddButtons = ({onExport, onAdvExport, onAdd, addTitle, settings, userPermissions, tableName, languageInfo}) => {
    let addPermissions;
    let exportPermissions;
    if (typeof (userPermissions) != 'undefined') {
        if (tableName === "mentee") {
            addPermissions = userPermissions.menteesCanAdd === "1";
            exportPermissions = userPermissions.menteesCanExport === "1";
        } else if (tableName === "users") {
            addPermissions = userPermissions.usersCanAdd === "1";
            exportPermissions = userPermissions.usersCanExport === "1";
        } else if (tableName === "organizations") {
            addPermissions = userPermissions.organizationsCanAdd === "1";
            exportPermissions = userPermissions.organizationsCanExport === "1";
        } else {
            addPermissions = userPermissions.tablesCanAdd === "1";
            exportPermissions = userPermissions.tablesCanExport === "1";
        }
    }
    return (<div className="col-12 col-sm text-left mb-2">
        {addPermissions === true &&
        <Button color="primary" onClick={() => onAdd() }>
            <AddIcon size={18} /> {' '}
            {addTitle}
        </Button>}
        {exportPermissions === true && <>

            {typeof(settings) !== 'undefined' && <>
                {settings.showExport !== false && <Button color="secondary" onClick={() => onExport() } className="ml-1">
                    <DownloadIcon size={18} /> {' '}
                    {languageInfo.buttons["exportData"]}
                </Button>}
            </>}

            {tableName === "mentee" && <>
                <Button color="secondary" onClick={() => onAdvExport() } className="ml-1">
                    <DownloadIcon size={18} /> {' '}
                    {languageInfo.buttons["exportDataAdv"]}
                </Button>
            </>}
        </>}
    </div>);
}

const DataListColumns = ({columns, sortDirection, sortFieldName, changeSort, hasStatus, languageCode, tableName, languageInfo}) => {
    return (<>
        {columns.map((column, index) => {
            if (column.dataField === 'active' || column.dataField === 'status') hasStatus = true;
            if (column.type === "bool") {
                if (column.sort ===  true) {
                    return (<th key={index} className="text-center cursor-pointer" onClick={() => changeSort(column.dataField, sortDirection) }>{languageInfo.components[tableName].columns[column.dataField]}</th>);
                } else {
                    return (<th key={index} className="text-center">{languageInfo.components[tableName].columns[column.dataField]}</th>);
                }
            } else {
                if (column.sort ===  true) {
                    return (<th key={index} className="cursor-pointer" onClick={() => changeSort(column.dataField, sortDirection) }>
                        {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'ASC' && <SortDown size={12} />}
                        {sortFieldName === column.dataField && typeof(sortDirection) !== 'undefined' && sortDirection === 'DESC' && <SortUp size={12} />}
                        {languageInfo.components[tableName].columns[column.dataField]}
                    </th>);
                } else {
                    if (column.dataField === 'tools') {
                        return (<th key={index} className="text-right">{languageInfo.components[tableName].columns[column.dataField]}</th>);
                    } else {
                        return (<th key={index}>{languageInfo.components[tableName].columns[column.dataField]}</th>);
                    }
                }
            }
        })}
    </>);
}

const DataListPages = ({columns, recordsPerPage, onChangeRecordsPerPage, currentPage, totalPages, onUpdatePage, languageInfo}) => {
    const pages = [];

    // GO BACK 1 PAGE
    if (parseInt(currentPage) === 1) {
        pages.push(<li key="0" className="page-item disabled"><span className="page-link">&laquo;</span></li>);
    } else {
        pages.push(<li key="-1" className="page-item"><button className="page-link" onClick={() => onUpdatePage(1)}>First</button></li>);
        pages.push(<li key="0" className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage-1)}>&laquo;</button></li>);
    }

    if (parseInt(totalPages) > 15)
    {
        let startPage = parseInt(currentPage) - 6;
        let endPage = parseInt(currentPage) + 6;
        if (startPage < 1) startPage=1;
        if (endPage > totalPages) endPage=totalPages;
        if (endPage < 15 && totalPages > 20) endPage = 15;
        for (let i=startPage; i <= endPage; i++) {
            if (i === parseInt(currentPage)) {
                pages.push(<li key={i} className="page-item active"><span className="page-link">{i}<span className="sr-only">({languageInfo.buttons["current"]})</span></span></li>);
            } else {
                pages.push(<li key={i} className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
            }
        }
    }
    else
    {
        for (let i=1; i <= parseInt(totalPages); i++) {
            if (i === parseInt(currentPage)) {
                pages.push(<li key={i} className="page-item active"><span className="page-link">{i}<span className="sr-only">({languageInfo.buttons["current"]})</span></span></li>);
            } else {
                pages.push(<li key={i} className="page-item"><button className="page-link" onClick={() => onUpdatePage(i)}>{i}</button></li>);
            }
        }
    }

    // GO FORWARD ONE PAGE
    if (parseInt(totalPages) === parseInt(currentPage)) {
        pages.push(<li key={totalPages + 1} className="page-item disabled"><span className="page-link">&raquo;</span></li>);
    } else {
        pages.push(<li key={totalPages + 1} className="page-item"><button className="page-link" onClick={() => onUpdatePage(currentPage+1)}>&raquo;</button></li>);
        pages.push(<li key={totalPages + 2} className="page-item"><button className="page-link" onClick={() => onUpdatePage(totalPages)}>{languageInfo.buttons["last"]}</button></li>);
    }

    return (<tr>
        <td colSpan={columns.length-1} className="pt-4">
            <nav aria-label="Page List">
                <ul className="pagination">
                    {pages}
                </ul>
            </nav>
        </td>
        <td className="text-right pt-4">
            <select
                className='form-control'
                id="recordsPerPage"
                name="recordsPerPage"
                value={recordsPerPage}
                //defaultValue={recordsPerPage}
                onChange={(e) => onChangeRecordsPerPage(e)}
                style={{maxWidth: '120px', display: 'inline'}}
            >
                <option value="">-- {languageInfo.buttons["select"]} --</option>
                <option value="5">5</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="10000">all</option>
            </select>
        </td>
    </tr>);
}
const SearchFunction = ({ settings, searchText, searchOverview, resetSearch, changeValue, searchKeyPress, languageInfo }) => {
    if (typeof(settings) !== 'undefined') {
        if (settings.showSearch !== false) {
            return (<div className="col-12 col-sm">
                <div className="row align-items-center">
                    <div className="col-12">
                        <div className="input-group">
                            <div className="input-group-prepend"><span className="input-group-text" id="basic-addon3">{languageInfo.buttons["searchTerm"]}:</span></div>
                            <input
                                id="searchText"
                                name="searchText"
                                value={searchText}
                                className="form-control"
                                onKeyPress={(e) => { searchKeyPress(e); } }
                                onChange={(e) => changeValue('searchText', e)}
                            />
                            <div className="input-group-append">
                                <Button color="success" onClick={() => searchOverview() }>
                                    <SearchIcon size={18} /> {' '}
                                    {languageInfo.buttons["search"]}
                                </Button>
                            </div>
                            <div className="input-group-append">
                                <Button color="secondary" onClick={() => resetSearch() }>
                                    <ResetIcon size={18} /> {' '}
                                    {languageInfo.buttons["reset"]}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }
    }

    return (null);
}
const DataListData = ({tableData, columns, tableName, onViewsUsers, onEdit, onDelete, hasStatus, onArchive, languageCode, userPermissions, languageInfo}) => {
    if (tableData.length !== 0) {
        return (<Fragment>
            {tableData.map((dataRow, index) => {
                return (<tr key={index}>
                    {columns.map((column, index) => {
                        let value = dataRow[column.dataField];
                        if (column.type === "bool")
                        {
                            return (<td key={index} style={{width: 75}} className="text-center">
                                {(value === 1 || value === '1' || value === true || value === "true") && <ActiveIcon className="text-success" size={18}/>}
                                {(value === 0 || value === '0' || value === false || value === "false") && <InActiveIcon className="text-danger" size={18}/>}
                            </td>);
                        }
                        else  if (column.type === "image")
                        {
                            if (value !== '') {
                                return (<td key={index}><img src={value} style={{maxWidth: 60}} alt="thumbnail" border="0" /></td>);
                            } else {
                                return (<td key={index}>&nbsp;</td>);
                            }
                        }
                        else if (column.dataField === "tools")
                        {
                            let chatPermissions = false;
                            let viewPermissions = false;
                            let editPermissions = false;
                            let deletePermissions = false;
                            let archivePermissions = false;

                            if (tableName === "mentee") {
                                chatPermissions = userPermissions.menteesCanChat === "1";
                                editPermissions = userPermissions.menteesCanEdit === "1";
                                archivePermissions = userPermissions.menteesCanArchive === "1";
                                deletePermissions = userPermissions.menteesCanDelete === "1";
                                viewPermissions = editPermissions === false;
                            } else if (tableName === "users") {
                                editPermissions = userPermissions.usersCanEdit === "1";
                                archivePermissions = userPermissions.usersCanArchive === "1";
                                deletePermissions = userPermissions.usersCanDelete === "1";
                                viewPermissions = editPermissions === false;
                            } else if (tableName === "organizations") {
                                editPermissions = userPermissions.organizationsCanEdit === "1";
                                archivePermissions = userPermissions.organizationsCanArchive === "1";
                                deletePermissions = userPermissions.organizationsCanDelete === "1";
                                viewPermissions = editPermissions === false && userPermissions.organizationsCanView === "1";
                            } else {
                                editPermissions = userPermissions.tablesCanEdit === "1";
                                deletePermissions = userPermissions.tablesCanDelete === "1";
                                archivePermissions = userPermissions.tablesCanArchive === "1";
                                viewPermissions = editPermissions === false && userPermissions.tablesCanView === "1";
                            }

                            return (<td key={index} nowrap="true" style={{width: 220}} className="text-right">
                                {viewPermissions === true && <Fragment><Button size="sm" color="primary" onClick={() => onArchive(dataRow.id)}><ViewIcon size={18} /></Button>{' '}</Fragment>}
                                {chatPermissions === true && <Fragment>{tableName === "mentee" && dataRow.active === '1' && <Fragment><a href={`/mentee/chat/${dataRow.id}`} className="btn btn-sm btn-success"><ChatIcon size={18} /></a>{' '}</Fragment>}</Fragment>}
                                {tableName === "organizations" && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><UsersIcons size={18} /></Button>{' '}</Fragment>}
                                {tableName === "users" && dataRow.status === '2' && <Fragment><Button size="sm" color="success" onClick={() => onViewsUsers(dataRow.id)}><EmailIcon size={18} /></Button>{' '}</Fragment>}

                                {typeof(dataRow.organizationId) !== 'undefined' && <Fragment>
                                    {(dataRow.organizationId === associationId || associationId === 1 || associationId === "1") && <Fragment>
                                        {editPermissions === true && <Fragment>
                                            {(dataRow.editButton === "true" || dataRow.editButton === true) && <Fragment>
                                                {tableName !== "mentee" && <Button size="sm" color="primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Button>}
                                                {tableName === "mentee" && <Link to={`/mentee/manage/${dataRow.id}`} className="btn btn-sm btn-primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Link>}
                                                {' '}</Fragment>}
                                        </Fragment>}
                                        {deletePermissions === true && <Fragment>
                                            {(dataRow.deleteButton === "true" || dataRow.deleteButton === true) && <Button size="sm" color="danger" onClick={() => onDelete(dataRow.id)}><DeleteIcon size={18} /></Button>}{' '}
                                        </Fragment>}
                                        {archivePermissions === true && <Fragment>
                                            {typeof(dataRow.active) !== 'undefined' && dataRow.active === '0' && <Fragment><Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><UnArchiveIcon size={18} /></Button>{' '}</Fragment>}
                                            {typeof(dataRow.active) !== 'undefined' && dataRow.active === '1' && <Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><ArchiveIcon size={18} /></Button>}
                                        </Fragment>}
                                    </Fragment>}
                                    {dataRow.organizationId !== associationId && <Fragment>

                                    </Fragment>}
                                </Fragment>}

                                {typeof(dataRow.organizationId) === 'undefined' && ((typeof(dataRow.editable) !== 'undefined' && dataRow.editable === "1") || typeof(dataRow.editable) === 'undefined') && <Fragment>
                                    {editPermissions === true && <Fragment>
                                        {(dataRow.editButton === "true" || dataRow.editButton === true) && <Fragment>
                                            {tableName !== "mentee" && <Button size="sm" color="primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Button>}
                                            {tableName === "mentee" && <Link to={`/mentee/manage/${dataRow.id}`} className="btn btn-sm btn-primary" onClick={() => onEdit(dataRow.id)}><EditIcon size={18} /></Link>}
                                        {' '} </Fragment>}
                                    </Fragment>}
                                    {deletePermissions === true && <Fragment>
                                        {(dataRow.deleteButton === "true" || dataRow.deleteButton === true) && <Button size="sm" color="danger" onClick={() => onDelete(dataRow.id)}><DeleteIcon size={18} /></Button>}{' '}
                                    </Fragment>}
                                    {archivePermissions === true && <Fragment>
                                        {typeof(dataRow.active) !== 'undefined' && dataRow.active === '0' && <Fragment><Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><UnArchiveIcon size={18} /></Button>{' '}</Fragment>}
                                        {typeof(dataRow.active) !== 'undefined' && dataRow.active === '1' && <Button size="sm" color="warning" onClick={() => onArchive(dataRow.id)}><ArchiveIcon size={18} /></Button>}
                                    </Fragment>}
                                </Fragment>}

                            </td>);
                        }
                        else
                        {
                            return (<td key={index}>{value}</td>);
                        }
                    })}
                </tr>);
            })}
        </Fragment>);
    }
    if (tableData.length === 0) {
        return (<tr>
            <td colSpan={columns.length} className="text-center">
                {languageText[languageCode].buttons["noRecords"]}
            </td>
        </tr>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    userPermissions: state.auth.user.userRole,
    data: state.pages.data,
});

export default connect(mapStateToProps, {archiveSubDataRecord})(DataList);
