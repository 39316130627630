import React, {Component} from "react";
import {CardHeader, CardBody, Button, Card, Col, Container, Row} from "reactstrap";
import {connect} from "react-redux";
import { getRecord, exportMentee } from "../client/actions/apiActions";
import MenteeSubNav from "../chcomponents/MenteeSubNav";
import {Link} from "react-router-dom";
import FormInput from "../chcomponents/form/FormInput";
import {ApiURL} from "../config";
import { languageText } from "../config";


class MenteeExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menteeId:0,
      menteeName: '',
      itemsToInclude: [],
      startDate: '',
      endDate: ''
    }
    this.selectedCheckboxes = new Set();
  }
  componentDidMount() {
    let menteeId = this.props.match.params.menteeId;
    let pathName = this.props.match.path.replace("/:menteeId", "");
    this.setState({ menteeId, pathName });
    this.props.getRecord('mentee', menteeId);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      if (this.props.data.dataType === "export" && this.props.data.status === 1 && this.props.data.code !== '' && typeof(this.props.data.code) !== 'undefined')
      {
        window.open(ApiURL + "/export-mentee/" + this.props.data.code);
      }

      if (this.props.data.dataType === "getRecord")
      {
        this.setState({menteeName: this.props.data.dataValues.firstName + " " + this.props.data.dataValues.lastName});
      }
    }
  }

  keyPress = (e) => {
    if (e.charCode === 13) this.sendMessage();
  };
  onChange = (type, e, field) => {
    let name = field.fieldName;
    let value = '';
    if (type === 'text')
    {
      value = e.target.value;
    }
    else if (type === 'phone')
    {
      value = e.target.value;
    }
    else if (type === 'email')
    {
      value = e.target.value;
    }
    else if (type === 'textarea')
    {
      value = e.target.value;
    }
    else if (type === 'toggle')
    {
      if (e.target.checked === true) value = '1';
      if (e.target.checked === false) value = '0';
    }
    else if (type === 'image')
    {
      value = e;
    }
    else if (type === 'editor')
    {
      value = e.target.getContent();
    }
    else if (type === 'select' || type === 'dropdown')
    {
      value = e.target.value;
    }
    else if (type === 'password')
    {
      value = e.target.value;
    }
    else
    {
      value = e.target.value;
    }

    this.setState({[name]: value});
  };
  handleCheckboxChange = (name, label) => {
    if (typeof(this.selectedCheckboxes[name]) === 'undefined') this.selectedCheckboxes[name] = new Set();
    if (this.selectedCheckboxes[name].has(label)) {
      this.selectedCheckboxes[name].delete(label);
    } else {
      this.selectedCheckboxes[name].add(label);
    }

    let checkedBoxes = ',';
    for (const checkbox of this.selectedCheckboxes[name]) {
      checkedBoxes += checkbox + ",";
    }
    this.setState({[name]: checkedBoxes});
  };
  exportData = () => {
    const { menteeId, itemsToInclude, startDate, endDate } = this.state;
    this.props.exportMentee(menteeId, startDate, endDate, itemsToInclude);
  }
  render() {
    const { menteeId, pathName, menteeName, itemsToInclude, startDate, endDate } = this.state;

    let languageCode = this.props.auth.user.languageCode;
    let languageInfo = languageText[languageCode];

    return (<Container fluid className="p-0">
        <Row className="mb-4">
          <Col>
            <Link className="btn btn-sm btn-secondary" to="/mentee/manage">{languageInfo.components.menteeUserExport.buttons.exportData}</Link>
          </Col>
          <Col className="text-right">
            {languageInfo.components.menteeUserExport.listSubTitle} : {menteeName}
          </Col>
        </Row>
      <Row>
        <Col md="3" xl="2">
          <MenteeSubNav id={menteeId} deactivateMentee={this.deactivateMentee} linkActive={pathName}/>
        </Col>
        <Col md="9" xl="10">
        <Card>
          <CardHeader>
            <h1 className="h3 mb-3">{languageInfo.components.menteeUserExport.pageTitle}</h1>
          </CardHeader>
          <CardBody>

            <div className="container">
              <div className="row">
                <h5 className="col-12">{languageInfo.buttons.exportDateRangeTitle}</h5>
                <div className="col-12">
                  <small>{languageInfo.buttons.exportDateRangeDescription}</small>
                </div>
                <FormInput
                    field={{fieldName:"startDate"}}
                    label={languageInfo.buttons.exportStartDate}
                    type="date"
                    id="startDate"
                    name="startDate"
                    value={startDate}
                    colSize={2}
                    onChange={this.onChange}
                />
                <FormInput
                    field={{fieldName:"endDate"}}
                    label={languageInfo.buttons.exportEndDate}
                    type="date"
                    id="endDate"
                    name="endDate"
                    value={endDate}
                    colSize={2}
                    onChange={this.onChange}
                />

              </div>
              <div className="row">
                <FormInput
                    field={{fieldName:"itemsToInclude"}}
                    label={languageInfo.components.menteeUserExport.itemsTitle}
                    type="fancyCheckbox"
                    id="itemsToInclude"
                    name="itemsToInclude"
                    value={itemsToInclude}
                    checkboxData={[
                      {
                        id: 8,
                        name: 'Profile Sheet'
                      },
                      {
                        id: 6,
                        name: 'Assessments',
                        es: 'Evaluaciones'
                      },
                      {
                        id: 3,
                        name: 'Goals'
                      },
                      {
                        id: 4,
                        name: 'Tasks'
                      },
                      {
                        id: 5,
                        name: 'Challenges'
                      },
                      {
                        id: 7,
                        name: 'Notes'
                      },
                      {
                        id: 1,
                        name: 'Files'
                      }
                    ]}
                    colSize={1}
                    handleCheckboxChange={this.handleCheckboxChange}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <Button onClick={()=>this.exportData()}>Export Mentee Data</Button>
                </div>
              </div>
            </div>

          </CardBody>
        </Card>
        </Col>
      </Row>

    </Container>);
  }
}
const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data
});

export default connect(mapStateToProps, {getRecord, exportMentee} )(MenteeExport);
