import React, {Component} from 'react';
class FormInputCheckboxElement extends Component {
    state = {
        isChecked: false,
    };
    toggleCheckboxChange = () => {
        const {handleCheckboxChange, value, name} = this.props;
        this.setState(({isChecked}) => (
            {
                isChecked: !isChecked,
            }
        ));
        handleCheckboxChange(name, value);
    };

    render() {
        const { id, checked, name, value, className} = this.props;
        return (
            <React.Fragment>
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    className={className}
                    defaultChecked={checked}
                    onChange={this.toggleCheckboxChange}
                />
            </React.Fragment>
        );
    }
}

export default FormInputCheckboxElement