import React, {Component} from "react";
import {connect} from "react-redux";
import {languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";
import {getList} from "../client/actions/apiActions";
import Loader from "../components/Loader";

const tableName = "resources";

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "organization",
                    sort: false
                },
                {
                    dataField: "name",
                    sort: true
                },
                {
                    dataField: "address",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                },
                {
                    fieldName: "active",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "firstName",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "lastName",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "email",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "phone",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "website",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "agencyServices",
                    fieldType: "checkbox",
                    valueOptions: [],
                    colSize: 1
                },
                {
                    fieldName: "services",
                    fieldType: "text",
                    colSize: 1
                },
                {
                    fieldName: "description",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "notes",
                    fieldType: "editor",
                    colSize: 1
                },
                {
                    fieldName: "address",
                    fieldType: "geoLocate",
                    colSize: 1
                }
            ],
        }
    }

    componentDidMount() {
        this.props.getList('configAgencyTypes', ['configAgencyServices']);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.data.configAgencyServices) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'agencyServices') {
                        fieldData[index].valueOptions = this.props.data.configAgencyServices;
                    }
                    return null;
                });
                this.setState({pageMode: 'data', fieldData});
            }
        }
    }

    render() {
        const { tableColumns, fieldData, pageMode } = this.state;
        let languageCode = this.props.auth.user.languageCode;

        if (pageMode === 'loading')
        {
            return (<Loader />);
        }
        else {
            return (<DataOverview
                singleEdit={false}
                tableName={tableName}
                languageTable={tableName}
                pageTitle={languageText[languageCode].components[tableName].pageTitle}
                listTitle={languageText[languageCode].components[tableName].listTitle}
                listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
                addTitle={languageText[languageCode].components[tableName].addTitle}
                addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
                editTitle={languageText[languageCode].components[tableName].editTitle}
                editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
                languageInfo={languageText[languageCode]}
                tableColumns={tableColumns}
                fieldData={fieldData}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(Resources);
