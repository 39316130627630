import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList} from "../client/actions/apiActions";

class MenteeGoals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "date",
                    sort: true
                },
                {
                    dataField: "name",
                    sort: true
                },
                {
                    dataField: "file",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "date",
                    fieldType: "hidden",
                    colSize: 3,
                    required: false
                },
                {
                    fieldName: "name",
                    fieldType: "text",
                    colSize: 1,
                    required: true,
                    requiredMessage: "Enter a name"
                },
                {
                    fieldName: "file",
                    fieldType: "file",
                    colSize: 1,
                    required: true,
                    requiredMessage: "Upload a file"
                },
            ],
            fieldDataDefault: {
                date: '',
                name: '',
                file: ''
            },
            languageCode: ''
        }
    }
    componentDidMount() {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({menteeId, languageCode, pathName, pageMode: 'data'});
    }

    render() {
        const { pageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (pageMode === 'loading')
        {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="menteeUserFiles"
                languageTable="Files"
                settings={{'showExport': false, 'showClone': false, 'showSearch': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(MenteeGoals);
