import React, {Fragment, Component} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-places-autocomplete";
import ShowInputError from "./ShowInputError";

class FormInputGeoLocate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            latLongAddress: ''
        }
    }

    selectLatLong = (value) => {
        console.error('selectLatLong', value)

        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.props.changeValue(this.props.name, value)
                this.props.changeValue("addressLatitude", latLng.lat)
                this.props.changeValue("addressLongitude", latLng.lng)
            })
            .catch(error => console.error('Error', error));
    };

    render() {
        const { name, value, label, colSize, placeholder, helpText, changeValue, error} = this.props;
        let divColSize;
        if (colSize === 0) divColSize = 0;
        if (colSize === 1) divColSize = 12;
        if (colSize === 2) divColSize = 6;
        if (colSize === 3) divColSize = 4;
        if (colSize === 4) divColSize = 8;
        return (<div className={`col-12 col-sm-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    <label htmlFor={name} className="col-sm-12 m-0 pt-2 pb-2">{label}</label>
                    <div className="col-sm-12">
                        <PlacesAutocomplete
                            value={value}
                            onChange={(value) => changeValue(name,value)}
                            onSelect={this.selectLatLong}
                            placeholder={placeholder}
                        >
                            {({getInputProps, suggestions, getSuggestionItemProps, loading, field}) => {
                                return (
                                    <div>
                                        <input
                                            name="newPassword"

                                            {...getInputProps({
                                                placeholder: placeholder,
                                                className: 'form-control form-control-sm',
                                            })}
                                            autoComplete="current-password"
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map( (suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            key: {index},
                                                            className,
                                                            style,
                                                        }
                                                        )}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )
                            }}
                        </PlacesAutocomplete>

                    </div>
                    {helpText && <Fragment>
                        <div className="col-4">&nbsp;</div>
                        <small id={name + "Help"} className="col-8 form-text text-muted">{helpText}</small></Fragment>}
                    {error && <ShowInputError error={error} />}
                </div>
            </div>
        </div>)
    }
}

export default FormInputGeoLocate
