import React, {Component} from "react";
import {timezones, languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";
import Loader from "../components/Loader";

const tableName = "users";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizationId: '',
            pageMode: "loading",

            tableColumns : [
                {
                    dataField: "active",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "image",
                    type: "image",
                    sort: true
                },
                {
                    dataField: "firstName",
                    sort: true
                },
                {
                    dataField: "lastName",
                    sort: true
                },
                {
                    dataField: "email",
                    sort: true
                },
                {
                    dataField: "role",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "titleUsersName",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "firstName",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "lastName",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "active",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "titleLogInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "email",
                    fieldType: "email",
                    colSize: 1,
                    required: true
                },
                {
                    fieldName: "password",
                    fieldType: "password",
                    colSize: 2,
                    required: true
                },
                {
                    fieldName: "passwordConfirm",
                    fieldType: "password",
                    colSize: 2,
                    required: true
                },
                {
                    fieldName: "titleContactInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "cellPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "workPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "timezone",
                    fieldType: "dropdown",
                    dropdownData: timezones,
                    colSize: 3
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "notes",
                    fieldType: "textarea",
                    colSize: 1
                },

                {
                    fieldName: "titleRoleInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "userRole",
                    fieldType: "radio",
                    colSize: 1,
                    required: true
                },

                {
                    fieldName: "titleAddressInfo",
                    fieldType: "title",
                    colSize: 1
                },
                {
                    fieldName: "address",
                    fieldType: "geoLocate",
                    colSize: 1
                },
                {
                    fieldName: "addressLatitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "addressLongitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "organizationId",
                    fieldType: "hidden",
                    colSize: 0
                },
            ],
            fieldDataDefault: {
            },
        }
    }
    componentDidMount() {
        let organizationId = '';
        if (typeof(this.props.match.params.organizationId) !== 'undefined') {
            organizationId = this.props.match.params.organizationId;
        }
        if (organizationId === '') {
            organizationId = this.props.auth.user.user.organizationId;
        }
        this.setState({organizationId: organizationId, pageMode: 'data'});
    }

    render() {
        const { pageMode, tableColumns, fieldData, fieldDataDefault, organizationId } = this.state;
        const { languageCode } = this.props.auth.user.user;
        if (pageMode === "data") {
        return (<DataOverview
            tableName={tableName}
            organizationId={organizationId}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            languageInfo={languageText[languageCode]}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);}else{return(<Loader />);}
    }
}
export default Users;
