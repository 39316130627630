import { combineReducers } from 'redux';

import authReducer from "./authReducer";
import pagesReducer from './pagesReducer';

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
    auth: authReducer,
    pages: pagesReducer,
    sidebar,
    layout,
    theme,
    toastr
})