import React, {Component} from "react";
import MenteePage from "../chcomponents/MenteePage";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import {getList} from "../client/actions/apiActions";

class MenteeAssessments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menteeId: 0,
            subPageMode: "loading",
            tableColumns : [],
            fieldData: [
                {
                    fieldName: "adverseExperiences",
                    fieldType: "fancyCheckbox",
                    colSize: 1
                },
                {
                    fieldName: "levelOfCare",
                    fieldType: "fancyCheckbox",
                    colSize: 1
                },
                {
                    fieldName: "offerTypes",
                    fieldType: "fancyCheckbox",
                    colSize: 1
                },
                {
                    fieldName: "programStatus",
                    fieldType: "fancyCheckbox",
                    colSize: 1
                },
                {
                    fieldName: "traumaInformStatus",
                    fieldType: "fancyCheckbox",
                    colSize: 1
                }
            ],
            fieldDataDefault: {},
            languageCode: ''
        }
    }
    componentDidMount() {
        let menteeId = this.props.match.params.menteeId;
        let languageCode = this.props.auth.user.languageCode;
        let pathName = this.props.match.path.replace("/:menteeId", "");
        this.setState({menteeId, languageCode, pathName});
        this.props.getList('configTraumaInformStatus', ['configProgramStatus', 'configOfferTypes', 'configLevelOfCare', 'configAdverseExperiences']);
    }
    componentWillUnmount() {
        this.setState({subPageMode: "loading"});
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.data.configTraumaInformStatus) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'traumaInformStatus') {
                        fieldData[index].checkboxData = this.props.data.configTraumaInformStatus;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
            if (typeof(this.props.data.configProgramStatus) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'programStatus') {
                        fieldData[index].checkboxData = this.props.data.configProgramStatus;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
            if (typeof(this.props.data.configOfferTypes) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'offerTypes') {
                        fieldData[index].checkboxData = this.props.data.configOfferTypes;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
            if (typeof(this.props.data.configLevelOfCare) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'levelOfCare') {
                        fieldData[index].checkboxData = this.props.data.configLevelOfCare;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
            if (typeof(this.props.data.configAdverseExperiences) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'adverseExperiences') {
                        fieldData[index].checkboxData = this.props.data.configAdverseExperiences;
                    }
                    return null;
                });
                this.setState({subPageMode: 'data', fieldData});
            }
        }
    }
    render() {
        const { subPageMode, pathName, menteeId, tableColumns, fieldData, fieldDataDefault, languageCode } = this.state;
        if (subPageMode === 'loading')
        {
            return (<Loader />);
        }
        else
        {
            return (<MenteePage
                tableName="menteeUserAssessments"
                languageTable="Assessments"
                singleEdit={true}
                settings={{'showExport': false, 'showClone': false, 'showSearch': false}}
                menteeId={menteeId}
                pathName={pathName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
                languageCode={languageCode}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(MenteeAssessments);
