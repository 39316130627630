import React, {Component} from "react";
import {languages, languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";
const tableName = "content";
class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "pageUrl",
                    sort: true
                },
                {
                    dataField: "pageTitle",
                    sort: true
                },
                {
                    dataField: "languageCode",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false
                }
            ],
            fieldData: [
                {
                    fieldName: "pageUrl",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Page URL"
                },
                {
                    fieldName: "pageTitle",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Page Title"
                },
                {
                    fieldName: "languageCode",
                    fieldType: "dropdown",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Language is required",
                    dropdownData: languages,
                },
                {
                    fieldName: "pageHTML",
                    fieldType: "content",
                    colSize: 1,
                    required: false,
                    requiredMessage: "Page Title"
                },
            ],
            fieldDataDefault: {
                languageCode: 'en',
                pageUrl: '',
                pageTitle: '',
                pageHTML: "",
            },
            showExportData: false
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault, showExportData } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            showExportData={showExportData}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            languageInfo={languageText[languageCode]}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default Content;
