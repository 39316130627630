import React, { Component } from "react";
import { Container } from "reactstrap";
import DataList from "./DataList";
import DataEdit from "./DataEdit";
import {
  getTable,
  getRecord,
  saveRecord,
  archiveRecord,
  deleteRecord,
  exportOverview,
  getAdvExportOptions,
  advExportReport,
  advExportMentees,
} from "../client/actions/apiActions";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toastr } from "react-redux-toastr";
import { ApiURL, languageText } from "../config";
import DataAdvancedExport from "./DataAdvancedExport";

function prepExportOptions(exportObject) {
  let outputObject = {};
  if (Object.keys(exportObject).length > 0) {
    Object.keys(exportObject).map((item) => {
      outputObject[item] = exportObject[item].split(",");
      return false;
    });
  }
  return outputObject;
}

class DataOverview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageMode: "loading",
      pageTitle: "",
      showExportData: true,
      listTitle: "",
      addTitle: "",
      editTitle: "",
      listSubTitle: "",
      addSubTitle: "",
      editSubTitle: "",
      tableColumns: [],
      tableData: [],
      fieldData: [],
      fieldDataResults: {},
      fieldDataDefault: {},
      fieldDataErrors: [],
      totalPages: 1,
      totalRecords: 0,
      searchText: "",
      searchFields: "name",
      changeValue: "",
      currentPage: 1,
      recordsPerPage: 25,
      sortDirection: "ASC",
      sortFieldName: "",
      settings: {},
      organizationId: "",
      key: 0,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right",
      advExport: false,
      advExports: {},
      checkedOptions: {},
    };
  }
  componentDidMount() {
    let fieldDataResults = [];
    let settings = {};
    let singleEdit = this.props.singleEdit;
    if (singleEdit !== true) singleEdit = false;

    let showExportData = this.props.showExportData;
    if (showExportData !== false) showExportData = true;

    if (singleEdit === true) {
      fieldDataResults = this.props.fieldDataResults;
    }
    if (typeof this.props.settings !== "undefined") {
      settings = this.props.settings;
    }

    let recordsPerPage = this.state.recordsPerPage;
    if (typeof this.props.recordsPerPage !== "undefined") {
      recordsPerPage = this.props.recordsPerPage;
    }

    let organizationId = this.state.organizationId;
    if (typeof this.props.organizationId !== "undefined") {
      organizationId = this.props.organizationId;
    }

    let sortFieldName = this.state.sortFieldName;
    if (typeof this.props.sortFieldName !== "undefined") {
      sortFieldName = this.props.sortFieldName;
    }

    let sortDirection = this.state.sortDirection;
    if (typeof this.props.sortDirection !== "undefined") {
      sortDirection = this.props.sortDirection;
    }

    let searchText = this.state.searchText;
    if (typeof this.props.searchText !== "undefined") {
      searchText = this.props.searchText;
    }
    this.setState({
      singleEdit: singleEdit,
      fieldDataResults: fieldDataResults,
      tableName: this.props.tableName,
      showExportData: showExportData,
      settings: settings,
      organizationId: organizationId,

      pageTitle: this.props.pageTitle,
      listTitle: this.props.listTitle,
      listSubTitle: this.props.listSubTitle,
      addTitle: this.props.addTitle,
      addSubTitle: this.props.addSubTitle,
      editTitle: this.props.editTitle,
      editSubTitle: this.props.editSubTitle,

      tableColumns: this.props.tableColumns,
      fieldData: this.props.fieldData,
      recordsPerPage: recordsPerPage,
      sortFieldName: sortFieldName,
      sortDirection: sortDirection,
      searchText: searchText,
    });
    if (this.props.tableName === "mentee") {
      this.props.getAdvExportOptions();
    }

    if (singleEdit === false)
      this.props.getTable(
        this.props.tableName,
        1,
        recordsPerPage,
        sortFieldName,
        sortDirection,
        searchText,
        organizationId,
      );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (
        this.props.data.dataType === "export" &&
        this.props.data.status === 1 &&
        this.props.data.code !== "" &&
        typeof this.props.data.code !== "undefined"
      ) {
        window.open(ApiURL + "/export/" + this.props.data.code);
      } else if (this.props.data.dataType === "addRecord") {
        this.setState({
          fieldDataResults: this.props.data.dataValues,
          pageMode: "add",
        });
      } else if (this.props.data.dataType === "getRecord") {
        this.setState({
          fieldDataResults: this.props.data.dataValues,
          pageMode: "edit",
        });
      } else if (this.props.data.dataType === "getAdvExportReport") {
        //alert(this.props.data.mentees.length + " mentees found");
        console.log("mentees returned", this.props.data.mentees.length);
      } else if (this.props.data.dataType === "deleteRecord") {
        this.refreshFunction();
      } else if (this.props.data.dataType === "getAdvExportOptions") {
        this.setState({
          advExports: this.props.data.advExports,
        });
      } else if (this.props.data.dataType === "saveRecord") {
        if (this.props.data.status === 1) {
          this.showToastr(
            "success",
            "Save Successful",
            "Record was successfully saved.",
          );
        } else {
          this.showToastr("danger", "Error Saving", this.props.data.message);
        }
        if (this.state.singleEdit !== true) {
          this.refreshFunction();
        } else {
          this.props.getRecord(this.state.tableName, this.props.data.recordId);
        }
      } else if (this.props.data.dataType === "archiveRecord") {
        this.refreshFunction();
      } else if (this.props.data.dataType === "getTable") {
        this.setState({
          tableData: this.props.data.dataValues,
          currentPage: this.props.data.currentPage,
          totalPages: this.props.data.totalPages,
          totalRecords: this.props.data.totalRecords,
          pageMode: "list",
        });
      }
    }
  }
  refreshFunction = (
    currentPage,
    recordsPerPage,
    sortFieldName,
    sortDirection,
    searchText,
  ) => {
    this.setState({ pageMode: "loading" });
    if (typeof currentPage === "undefined")
      currentPage = this.state.currentPage;
    if (typeof recordsPerPage === "undefined")
      recordsPerPage = this.state.recordsPerPage;
    if (typeof sortFieldName === "undefined")
      sortFieldName = this.state.sortFieldName;
    if (typeof sortDirection === "undefined")
      sortDirection = this.state.sortDirection;
    if (typeof searchText === "undefined") searchText = this.state.searchText;

    let organizationId = this.state.organizationId;
    if (typeof this.props.organizationId !== "undefined") {
      organizationId = this.props.organizationId;
    }
    if (typeof organizationId === "undefined") {
      organizationId = "";
    }
    this.props.getTable(
      this.state.tableName,
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
      organizationId,
    );
  };
  addFunction = () => {
    this.setState({ pageMode: "loading" });
    this.props.getRecord(this.state.tableName, "0");
  };
  editFunction = (id) => {
    this.setState({ pageMode: "loading" });
    this.props.getRecord(this.state.tableName, id);
  };
  deleteFunction = (id) => {
    this.props.deleteRecord(this.state.tableName, id);
  };
  archiveRecord = (id) => {
    this.props.archiveRecord(this.state.tableName, id);
  };
  updatePage = (currentPage) => {
    this.setState({ currentPage: currentPage });
    this.refreshFunction(currentPage, this.state.recordsPerPage);
  };
  deleteConfirmFunction = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you wish to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteFunction(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  saveFunction = (id, data) => {
    let prevPageMode = this.state.pageMode;
    this.setState({ pageMode: "loading" });
    let errors = 0;
    let languageCode = "";
    if (typeof this.props.auth.user.languageCode !== "undefined") {
      languageCode = this.props.auth.user.languageCode;
    }
    if (typeof this.props.auth.user.user !== "undefined") {
      if (typeof this.props.auth.user.user.languageCode !== "undefined") {
        languageCode = this.props.auth.user.languageCode;
      }
    }

    let fieldDataErrors = [];
    const { fieldData, tableName } = this.state;
    let languageTable = this.props.languageTable;
    if (typeof languageTable === "undefined") {
      languageTable = tableName;
    }
    if (
      this.props.organizationId !== "" &&
      typeof this.props.organizationId !== "undefined" &&
      this.state.tableName === "users"
    ) {
      data["organizationId"] = this.props.organizationId;
    }
    fieldData.map((field) => {
      if (
        field.fieldType === "radio" &&
        (data[field.fieldName] === 0 || data[field.fieldName] === "0")
      ) {
        errors = 1;
        fieldDataErrors[field.fieldName] =
          languageText[languageCode].components[languageTable].fields[
            field.fieldName
          ].requiredMessage;
        this.showToastr(
          "error",
          languageText[languageCode].components[languageTable].fields[
            field.fieldName
          ].requiredMessage,
          "",
        );
      } else {
        if (
          field.required === true &&
          (data[field.fieldName] === "" ||
            typeof data[field.fieldName] === "undefined")
        ) {
          errors = 1;
          fieldDataErrors[field.fieldName] =
            languageText[languageCode].components[languageTable].fields[
              field.fieldName
            ].requiredMessage;
          this.showToastr(
            "error",
            languageText[languageCode].components[languageTable].fields[
              field.fieldName
            ].requiredMessage,
            "",
          );
        }
      }
      return false;
    });
    if (errors === 1) {
      this.setState({
        fieldDataErrors: fieldDataErrors,
        pageMode: prevPageMode,
        key: Math.floor(Math.random() * 101),
      });
    } else {
      this.props.saveRecord(this.state.tableName, id, data);
      this.setState({ fieldDataErrors: [] });
    }
  };
  exportFunction = () => {
    this.props.exportOverview(this.state.tableName);
  };
  openAdvExport = () => {
    this.setState({
      advExport: true,
    });
  };
  closeAdvExport = () => {
    this.setState({
      advExport: false,
    });
  };

  changeValue = (name, e) => {
    let value = e.target.value;
    this.setState({ [name]: value });
  };
  resetSearch = () => {
    this.setState({ searchText: "", pageMode: "loading" });
    let searchText = "";
    let currentPage = this.state.currentPage;
    let recordsPerPage = this.state.recordsPerPage;
    let sortFieldName = this.state.sortFieldName;
    let sortDirection = this.state.sortDirection;
    this.refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
    );
  };
  searchOverview = () => {
    this.setState({ pageMode: "loading" });
    let searchText = this.state.searchText;
    let currentPage = this.state.currentPage;
    let recordsPerPage = this.state.recordsPerPage;
    let sortFieldName = this.state.sortFieldName;
    let sortDirection = this.state.sortDirection;
    this.refreshFunction(
      currentPage,
      recordsPerPage,
      sortFieldName,
      sortDirection,
      searchText,
    );
  };
  searchKeyPress = (e) => {
    if (e.key === "Enter") {
      this.searchOverview();
    }
  };
  closeFunction = () => {
    this.setState({ pageMode: "loading", fieldDataErrors: [] });
    this.refreshFunction();
  };
  onViewsUsers = (organizationId) => {
    window.location.href = "/organizations/users/" + organizationId;
  };
  changeRecordsPerPage = (e) => {
    this.setState({ [e.target.name]: e.target.value, currentPage: 1 });
    this.refreshFunction(1, e.target.value);
  };
  changeSort = (sortFieldName, sortDirection) => {
    if (sortDirection === "DESC") sortDirection = "ASC";
    else sortDirection = "DESC";
    this.setState({ pageMode: "loading", sortFieldName, sortDirection });
    this.refreshFunction(
      this.state.currentPage,
      this.state.recordsPerPage,
      sortFieldName,
      sortDirection,
    );
  };
  showToastr = (type, title, message) => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position,
    };
    const toastrInstance =
      type === "info"
        ? toastr.info
        : type === "warning"
        ? toastr.warning
        : type === "error"
        ? toastr.error
        : toastr.success;

    toastrInstance(title, message, options);
  };

  handleCheckboxChange = (fieldName, value) => {
    let checkedOptions = this.state.checkedOptions;
    let tmpVal = [];
    if (typeof checkedOptions[fieldName] === "undefined") {
      checkedOptions[fieldName] = "";
    }

    if (checkedOptions[fieldName] !== "") {
      tmpVal = checkedOptions[fieldName].split(",");
      if (tmpVal.indexOf(value) === -1) {
        tmpVal.push(value);
      } else {
        delete tmpVal[tmpVal.indexOf(value)];
      }
    } else {
      tmpVal.push(value);
    }

    let tmpArray = [];
    tmpVal.forEach((value) => {
      tmpArray.push(value);
    });
    checkedOptions[fieldName] = tmpArray.join(",");
    this.setState({
      checkedOptions: checkedOptions,
    });
  };
  advExportMentees = () => {
    let checkedOptions = prepExportOptions(this.state.checkedOptions);
    this.props.advExportMentees(checkedOptions);
  };
  advExportReport = () => {
    let checkedOptions = prepExportOptions(this.state.checkedOptions);
    this.props.advExportReport(checkedOptions);
  };
  onChange = (type, e) => {
    let checkedOptions = this.state.checkedOptions;
    checkedOptions[e.target.name] = e.target.value;
    this.setState({
      checkedOptions: checkedOptions,
    });
  };

  render() {
    const {
      tableColumns,
      tableData,
      fieldData,
      fieldDataResults,
      sortFieldName,
      sortDirection,
      checkedOptions,
      fieldDataErrors,
      singleEdit,
      currentPage,
      totalPages,
      totalRecords,
      pageTitle,
      addTitle,
      listTitle,
      listSubTitle,
      tableName,
      advExport,
      recordsPerPage,
      searchText,
      settings,
      organizationId,
    } = this.state;
    let pageMode = this.state.pageMode;
    let languageTable = this.props.languageTable;
    let languageInfo = this.props.languageInfo;

    if (typeof languageTable === "undefined") languageTable = tableName;
    if (singleEdit === true) pageMode = "edit";
    if (advExport === true) {
      return (
        <DataAdvancedExport
          closeAdvExport={this.closeAdvExport}
          advExports={this.state.advExports}
          languageInfo={languageInfo}
          checkedOptions={checkedOptions}
          onChange={this.onChange}
          handleCheckboxChange={this.handleCheckboxChange}
          advExportMentees={this.advExportMentees}
          advExportReport={this.advExportReport}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
        />
      );
    }
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{pageTitle}</h1>
        {pageMode === "loading" && <Loader />}
        {pageMode === "list" && (
          <DataList
            title={listTitle}
            addTitle={addTitle}
            slogan={listSubTitle}
            columns={tableColumns}
            tableData={tableData}
            tableName={tableName}
            sortFieldName={sortFieldName}
            sortDirection={sortDirection}
            searchText={searchText}
            settings={settings}
            currentPage={currentPage}
            totalPages={totalPages}
            totalRecords={totalRecords}
            recordsPerPage={recordsPerPage}
            languageTable={languageTable}
            languageInfo={languageInfo}
            organizationId={organizationId}
            resetSearch={this.resetSearch}
            onViewsUsers={this.onViewsUsers}
            changeValue={this.changeValue}
            searchOverview={this.searchOverview}
            searchKeyPress={this.searchKeyPress}
            onUpdatePage={this.updatePage}
            onAdd={this.addFunction}
            onExport={this.exportFunction}
            onAdvExport={this.openAdvExport}
            onChangeRecordsPerPage={this.changeRecordsPerPage}
            changeSort={this.changeSort}
            onEdit={this.editFunction}
            onDelete={this.deleteConfirmFunction}
            onArchive={this.archiveRecord}
            key={this.state.key}
          />
        )}

        {(pageMode === "add" || pageMode === "view" || pageMode === "edit") && (
          <DataEdit
            mode={pageMode}
            singleEdit={singleEdit}
            title={this.state[pageMode + "Title"]}
            slogan={this.state[pageMode + "SubTitle"]}
            tableName={tableName}
            languageTable={languageTable}
            languageInfo={languageInfo}
            organizationId={organizationId}
            settings={settings}
            dataFields={fieldData}
            dataResults={fieldDataResults}
            dataErrors={fieldDataErrors}
            key={this.state.key}
            onArchive={this.archiveRecord}
            onSave={this.saveFunction}
            onCancel={this.closeFunction}
          />
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
});
export default connect(mapStateToProps, {
  getTable,
  getRecord,
  saveRecord,
  archiveRecord,
  deleteRecord,
  exportOverview,
  getAdvExportOptions,
  advExportReport,
  advExportMentees,
})(DataOverview);
