import React, { Component} from "react";
import { Card, CardHeader, CardTitle, ListGroup, ListGroupItem} from "reactstrap";
import { User as ProfileIcon,MessageSquare as ChatIcon, List as ListIcon, Paperclip as FilesIcon, Edit as NotesIcon, Download as ExportIcon, Clipboard as AssessmentsIcon, EyeOff as ArchiveIcon, Settings as SettingsIcon } from "react-feather";

import {languageText} from "../config";
import {connect} from "react-redux";

class MenteeSubNav extends Component {
    render() {
        const {  languageCode } = this.props.auth.user;
        const { linkActive, id, userPermissions } = this.props;
        let menteeProfile = userPermissions.tablesCanAccess.search(",menteeProfile,");
        let menteeAssessments = userPermissions.tablesCanAccess.search(",menteeAssessments,");
        let menteeChat = userPermissions.tablesCanAccess.search(",menteeChat,");
        let menteeGoals = userPermissions.tablesCanAccess.search(",menteeGoals,");
        let menteeTasks = userPermissions.tablesCanAccess.search(",menteeTasks,");
        let menteeChallenges = userPermissions.tablesCanAccess.search(",menteeChallenges,");
        let menteeNotes = userPermissions.tablesCanAccess.search(",menteeNotes,");
        let menteeFiles = userPermissions.tablesCanAccess.search(",menteeFiles,");
        let menteeExport = userPermissions.tablesCanAccess.search(",menteeExport,");
        let menteeResetPassword = userPermissions.tablesCanAccess.search(",menteeResetPassword,");
        let menteeDeactivate = userPermissions.tablesCanAccess.search(",menteeDeactivate,");

        return (<Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0 text-center font-weight-bold">
                    {languageText[languageCode].components["menteeLinks"].links.title}
                </CardTitle>
            </CardHeader>
            <ListGroup flush>
                {menteeProfile !== -1 && <ListGroupItem tag="a" href={`/mentee/manage/${id}`} action active={linkActive === "/mentee/manage"}>
                    <ProfileIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link9}
                </ListGroupItem>}
                {menteeAssessments !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-assessments/${id}`} action active={linkActive === "/mentee/mentee-assessments"}>
                    <AssessmentsIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link7}
                </ListGroupItem>}
                {menteeChat !== -1 && <ListGroupItem tag="a" href={`/mentee/chat/${id}`} action active={linkActive === "/mentee/chat"}>
                    <ChatIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link0}
                </ListGroupItem>}
                {menteeGoals !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-goals/${id}`} action active={linkActive === "/mentee/mentee-goals"}>
                    <ListIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link1}
                </ListGroupItem>}
                {menteeTasks !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-tasks/${id}`} action active={linkActive === "/mentee/mentee-tasks"}>
                    <ListIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link2}
                </ListGroupItem>}
                {menteeChallenges !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-challenges/${id}`} action active={linkActive === "/mentee/mentee-challenges"}>
                    <ListIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link3}
                </ListGroupItem>}
                {menteeNotes !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-notes/${id}`} action active={linkActive === "/mentee/mentee-notes"}>
                    <NotesIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link4}
                </ListGroupItem>}
                {menteeFiles !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-files/${id}`} action active={linkActive === "/mentee/mentee-files"}>
                    <FilesIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link5}
                </ListGroupItem>}
                {menteeExport !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-export/${id}`} action active={linkActive === "/mentee/mentee-export"}>
                    <ExportIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link6}
                </ListGroupItem>}
                {menteeResetPassword !== -1 && <ListGroupItem tag="a" href={`/mentee/mentee-password/${id}`} action className="bg-warning text-white" active={linkActive === "/mentee/mentee-password"}>
                    <SettingsIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link10}
                </ListGroupItem>}
                {menteeDeactivate !== -1 && <ListGroupItem tag="button" onClick={() => this.props.deactivateMentee()} action className="bg-danger text-white">
                    <ArchiveIcon size={18} /> {' '} {languageText[languageCode].components["menteeLinks"].links.link8}
                </ListGroupItem>}
            </ListGroup>
        </Card>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth.user,
    userPermissions: state.auth.user.userRole
});

export default connect(mapStateToProps)(MenteeSubNav);