import {
  Button,
  Modal as ModalWindow,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import React, { Fragment } from "react";

const Modal = ({
  handleClose,
  show,
  children,
  name,
  title,
  onSave,
  className,
  saveButton,
}) => {
  return (
    <ModalWindow
      isOpen={show}
      toggle={() => handleClose(name)}
      className={className}
    >
      <ModalHeader toggle={() => handleClose(name)}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => onSave()}>
          {saveButton !== "undefined" && <Fragment>{saveButton}</Fragment>}
          {saveButton === "undefined" && <Fragment>Update</Fragment>}
        </Button>{" "}
        <Button color="secondary" onClick={() => handleClose(name)}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWindow>
  );
};
export default Modal;
