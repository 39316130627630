import React, {Component} from "react";
import { languageText } from "../config";
import {connect} from "react-redux";
import { Card, Col, Row} from "reactstrap";
import SubDataOverview from "../chcomponents/SubDataOverview";
import MenteeSubNav from "../chcomponents/MenteeSubNav";
import CardBody from "reactstrap/es/CardBody";
import Loader from "../components/Loader";
import {Link, Redirect} from "react-router-dom";
import { getMentee, archiveRecord } from "../client/actions/apiActions";

class MenteePage extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            menteeId: 0,
            pageMode: "loading",
            tableColumns : [],
            fieldData: [],
            fieldDataDefault: {},
            languageCode: 'en',
            tableName: '',
            languageTable: '',
            settings: {},
            pathName: '',
            menteeName: ''
        }
    }
    componentDidMount()
    {
        let settings = this.props.settings;
        let fieldData = this.props.fieldData;
        let menteeId = this.props.menteeId;
        let languageCode = this.props.auth.user.languageCode;;
        let languageTable = this.props.languageTable;
        let pathName = this.props.pathName;
        let singleEdit = this.props.singleEdit;
        let tableName = this.props.tableName;
        let tableColumns = this.props.tableColumns;

        if (tableName !== 'mentee') {
            languageTable = "menteeUser" + languageTable;
            this.props.getMentee(menteeId);
        }

        this.setState({
            singleEdit, pathName, settings, tableName, languageTable,
            pageMode:'data', menteeId: menteeId, tableColumns: tableColumns,
            fieldData:fieldData, languageCode:languageCode
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevProps !== this.props)
        {
            if (typeof(this.props.data) !== 'undefined')
            {
                if (this.props.data.dataType === "archiveRecord" && this.props.data.status === 1 && this.state.tableName === "mentee")
                {
                    this.setState({pageMode: 'redirect'});
                }
                else
                {
                    if (typeof(this.props.data.dataValues) !== 'undefined')
                    {
                        if (typeof(this.props.data.dataValues.firstName) !== 'undefined')
                        {
                            this.setState({menteeName: this.props.data.dataValues.firstName + " " + this.props.data.dataValues.lastName});
                        }
                    }
                }
            }
        }
    }
    deactivateMentee = () => {
        this.props.archiveRecord("mentee", this.state.menteeId);
    }

    render() {
        const { pathName, pageMode, menteeId, menteeName, tableColumns, fieldData, fieldDataDefault, languageCode, languageTable, tableName, singleEdit, settings} = this.state;

        if (pageMode === 'loading')
        {
            return (<Loader />);
        }
        else if (pageMode === 'redirect')
        {
            return (<Redirect
                to="/mentee/manage"
            />);
        }
        else
        {
            return (<>
                <Row className="mb-4">
                    <Col>
                        <Link className="btn btn-sm btn-secondary" to="/mentee/manage">Back to Mentees</Link>
                    </Col>
                    <Col className="text-right h2">
                        Viewing : {menteeName}
                    </Col>
                </Row>
                <Row>
                    <Col md="3" xl="2">
                        <MenteeSubNav
                            id={menteeId}
                            deactivateMentee={this.deactivateMentee}
                            linkActive={pathName}
                        />
                    </Col>
                    <Col md="9" xl="10">
                        <Card>
                            <CardBody>
                                <div className="container">
                                    <div className="row align-items-end">
                                        <SubDataOverview
                                            menteeId={menteeId}
                                            tableName={tableName}
                                            singleEdit={singleEdit}
                                            settings={{...settings, "showCard": false}}
                                            languageTable={languageTable}
                                            pageTitle={languageText[languageCode].components[languageTable].pageTitle}
                                            listTitle={languageText[languageCode].components[languageTable].listTitle}
                                            listSubTitle={languageText[languageCode].components[languageTable].listSubTitle}
                                            addTitle={languageText[languageCode].components[languageTable].addTitle}
                                            addSubTitle={languageText[languageCode].components[languageTable].addSubTitle}
                                            editTitle={languageText[languageCode].components[languageTable].editTitle}
                                            editSubTitle={languageText[languageCode].components[languageTable].editSubTitle}
                                            tableColumns={tableColumns}
                                            fieldData={fieldData}
                                            fieldDataDefault={fieldDataDefault}
                                        />
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, { getMentee, archiveRecord } )(MenteePage);
