import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";

import { languageText } from "../config";
import DataOverview from "./DataOverview";
import {connect} from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "../components/Loader";
import ProfileLinks from "./ProfileLinks";

const tableName = "usersFiles";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'loading',
      firstName: '',
      lastName: '',
      email: '',
      tableColumns : [
        {
          dataField: "active",
          type: "bool",
          sort: false,
        },
        {
          dataField: "name",
          sort: true
        },
        {
          dataField: "file",
          sort: true
        },
        {
          dataField: "date",
          sort: true
        },
        {
          dataField: "tools",
          sort: false,
        }
      ],
      fieldData: [
        {
          fieldName: "name",
          fieldType: "text",
          colSize: 2,
          required: true
        },
        {
          fieldName: "file",
          fieldType: "file",
          colSize: 2,
          required: true
        },
      ],
      fieldDataResults: []
    };
  }

  componentDidMount() {
    this.props.getProfileInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      if (typeof(this.props.data) !== 'undefined')
      {
        if (typeof(this.props.data.userProfile) !== 'undefined')
        {
          let fieldDataResults = this.props.data.userProfile;
          this.setState({
            viewMode: 'data',
            fieldDataResults
          });
        }
      }
    }
  }

  render() {
    const {fieldDataResults, viewMode, fieldData, tableColumns} = this.state;
    if (viewMode === 'loading') {
      return (<Loader />);
    } else {
      const { languageCode } = this.props.auth.user;
      return (<Container fluid className="p-0">
        <Row>
          <Col md="3" xl="2">
            <ProfileLinks linkActive="/profile-files" />
          </Col>
          <Col md="9" xl="10">

            <DataOverview
                tableName={tableName}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataResults={fieldDataResults}
                languageTable={tableName}
                settings={{ 'showExport': false, 'showClone': false, 'showSearch': false }}
                pageTitle={languageText[languageCode].components[tableName].pageTitle}
                listTitle={languageText[languageCode].components[tableName].listTitle}
                listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
                addTitle={languageText[languageCode].components[tableName].addTitle}
                addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
                editTitle={languageText[languageCode].components[tableName].editTitle}
                editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
                languageInfo={languageText[languageCode]}
            />

          </Col>
        </Row>
      </Container>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {getProfileInfo} )(Profile);
