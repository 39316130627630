import axios from "axios";
import { returnErrors } from "./messages";
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    SAVE_FORGOT_PASS,
    CHECK_VERIFICATION_CODE
} from './types';
import { ApiURL } from "../../config";


// CHECK TOKEN AND LOAD USER
export const loadUser = (appVersion) => async (dispatch, getState) => {
    dispatch({ type: USER_LOADING });
    try {
        let res = await axios.get(`${ApiURL}/auth/user`, tokenConfig(getState));
        dispatch({type: USER_LOADED, payload: res.data});
    }
    catch (err) {
        dispatch({type: AUTH_ERROR});
    }
};
export const login = (username, password) => async (dispatch) => {
    try {
        let res = await axios.post(
            `${ApiURL}/auth/login`,
            { username: username, password: password },
            {withCredentials: true}
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response, err.error));
        dispatch({
            type: LOGIN_FAIL
        })
    }
};
export const logout = () => (dispatch, getState) => {
    axios
        .post(`${ApiURL}/auth/logout`, null, tokenConfig(getState))
        .then(res=>{
            dispatch({type: LOGOUT_SUCCESS});
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
        })
};
export const forgotPass = (username) =>  async (dispatch) => {
    try {
        let res = await axios.post(
            `${ApiURL}/auth/forgotPass`,
            { username: username },
            {}
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
    }
    catch (err) {
        dispatch(returnErrors(err.response, err.error));
        dispatch({
            type: LOGIN_FAIL
        })
    }
};
export const saveForgotPass = ( username, verificationCode, password, passwordConfirm ) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/auth/saveForgotPass`, { username, verificationCode, password, passwordConfirm }, tokenConfig(getState));
        dispatch ({
            type: SAVE_FORGOT_PASS,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};
export const checkVerificationCode = ( verificationCode, username ) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${ApiURL}/auth/checkVerificationCode`, { verificationCode:verificationCode, username:username }, tokenConfig(getState));
        dispatch ({
            type: CHECK_VERIFICATION_CODE,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};

export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization']= token;
    }
    return config;
};