import React, {Component} from "react";
import DataOverview from "../chcomponents/DataOverview";
import {languageText} from "../config"; // Import css

const tableName = "bugTracker";
class MetaData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            searchColumns : [
                "Status",
                "Section",
                "Title",
                "Description"
            ],
            tableColumns : [
                {
                    dataField: "status",
                    sort: true
                },
                {
                    dataField: "section",
                    sort: true
                },
                {
                    dataField: "assignedTo",
                    sort: true
                },
                {
                    dataField: "title",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "status",
                    fieldType: "dropdown",
                    dropdownData: [
                        {
                            name: "Active",
                            id: "Active"
                        },
                        {
                            name: "In Progress",
                            id: "In Progress"
                        },
                        {
                            name: "Completed",
                            id: "Completed"
                        },
                        {
                            name: "Need More Info",
                            id: "Need More Info"
                        },
                        {
                            name: "Feature Ideas",
                            id: "Feature Ideas"
                        }
                    ],
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "section",
                    fieldType: "text",
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "assignedTo",
                    fieldType: "dropdown",
                    dropdownData: [
                        {
                            name: "Ian",
                            id: "Ian"
                        },
                        {
                            name: "Brook",
                            id: "Brook"
                        },
                        {
                            name: "Other",
                            id: "Other"
                        }
                    ],
                    colSize: 3,
                    required: true,
                },
                {
                    fieldName: "title",
                    fieldType: "text",
                    colSize: 1,
                    required: false,
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1,
                    required: false,
                },
                {
                    fieldName: "file",
                    fieldType: "file",
                    colSize: 1,
                    required: false,
                },
                {
                    fieldName: "description",
                    fieldType: "editor",
                    colSize: 1,
                    required: false,
                },
                {
                    fieldName: "notes",
                    fieldType: "editor",
                    colSize: 1,
                    required: false,
                }
            ],
            fieldDataDefault: {
                status: 1
            },
            sortFieldName: 'status',
            sortDirection: 'ASC'
        }
    }
    render() {
        const { searchColumns, tableColumns, fieldData, fieldDataDefault, sortFieldName, sortDirection } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            pageTitle={languageText[languageCode].components[tableName].pageTitle}
            listTitle={languageText[languageCode].components[tableName].listTitle}
            listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
            addTitle={languageText[languageCode].components[tableName].addTitle}
            addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
            editTitle={languageText[languageCode].components[tableName].editTitle}
            editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
            languageInfo={languageText[languageCode]}
            searchColumns={searchColumns}
            tableColumns={tableColumns}
            sortFieldName={sortFieldName}
            sortDirection={sortDirection}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default MetaData;
