import React, { Component, Fragment } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  ListGroupItem,
  Media,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import {
  getChatRooms,
  getChatMessages,
  startNewChat,
} from "../client/actions/apiActions";
import { ChatServer } from "../config";
import Loader from "../components/Loader";
import socketIOClient from "socket.io-client";
import FormInput from "../chcomponents/form/FormInput";
import {
  MessageSquare as ChatIcon,
  Send as SendIcon,
  Search as SearchIcon,
} from "react-feather";
import Modal from "../chcomponents/Modal";

let tmpMessages = [];
let socket;

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMessage: "",
      searchMembersInput: "",
      groups: [],
      messages: [],
      addChatMemberList: [],
      dataResults: [],
      roomId: 0,
      roomName: "",
      showNewChatModal: false,
      roomProfileImage: "",
      timestamp: "no timestamp yet",
    };
    socket = socketIOClient(ChatServer, {
      transports: ["polling"],
      forceNew: true,
      reconnectionDelay: 3000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      jsonp: false,
      secure: true,
    });
  }
  componentDidMount() {
    this.props.getChatRooms();
    this.configureSocket();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props) {
      if (this.props.data.dataType === "newChatRoom") {
        window.location.href = "/chat/" + this.props.data.chatRoom;
      }
      if (this.props.data.dataType === "chatRooms") {
        if (typeof this.props.match.params.chatRoom !== "undefined") {
          this.selectChatRoom(this.props.match.params.chatRoom);
        }
        let dataResults = [];
        dataResults["addChatMemberList"] = this.props.data.addChatMemberList;
        this.setState({
          groups: this.props.rooms,
          originalGroups: this.props.rooms,
          addChatMemberList: this.props.data.addChatMemberList,
          dataResults: dataResults,
          // messages: this.props.data.messages
        });
      }
      if (this.props.data.dataType === "chatMessages") {
        this.setState({
          messages: this.props.data.chatMessages,
          roomProfileImage: this.props.data.roomProfileImage,
          roomName: this.props.data.roomName,
        });
        socket.emit(
          "submitToken",
          this.props.data.roomId,
          this.props.auth.token,
        );
      }
    }
    this.scrollToBottom();
  }
  configureSocket = () => {
    socket.on("connect", function onConnect() {
      socket.emit("connected");
    });
    socket.on("message", (message) => {
      let messages = this.state.messages;
      messages.push(message);
      this.setState({ messages });
      this.scrollToBottom();
    });
  };
  selectChatRoom = (roomId) => {
    this.setState({ roomId });
    //socket.emit('joinRoom', {roomId: roomId});
    this.props.getChatMessages(roomId);
  };
  updateChatHistory(entry) {
    this.setState({ messages: this.state.messages.concat(entry) });
  }
  sendMessage() {
    const { inputMessage } = this.state;
    if (!inputMessage) return;
    socket.emit("chatMessage", {
      message: inputMessage,
      messageType: "Message",
    });
    return this.setState({ inputMessage: "" });
  }
  keyPress = (e) => {
    if (e.charCode === 13) this.sendMessage();
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onInputChange = (type, e, field) => {
    let dataResults = this.state.dataResults;
    let tmpValue = "";
    if (type === "text") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "phone") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "email") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "textarea") {
      dataResults[field.fieldName] = e.target.value;
    } else if (type === "toggle") {
      if (e.target.checked === true) dataResults[field.fieldName] = "1";
      if (e.target.checked === false) dataResults[field.fieldName] = "0";
    } else if (type === "image") {
      dataResults[field.fieldName] = e;
    } else if (type === "editor") {
      dataResults[field.fieldName] = e.target.getContent();
    } else if (type === "select" || type === "dropdown") {
      dataResults[e.target.name] = e.target.value;
    } else if (type === "password") {
      dataResults[field.fieldName] = e.target.value;
    } else {
      dataResults[field.fieldName] = e.target.value;
    }
    if (typeof field !== "undefined") {
      if (typeof field.callBackFunction !== "undefined") {
        this.props[field.callBackFunction](tmpValue);
      }
    }
    this.setState({ dataResults: dataResults });
  };
  scrollToBottom() {
    this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
  }
  onSaveNewChat = () => {
    this.props.startNewChat(this.state.dataResults["newChat"]);
  };
  modalOpen = (name) => {
    this.setState({ [name]: true });
  };
  modalClose = (name) => {
    this.setState({
      modalInputName: "",
      [name]: false,
    });
  };

  searchMembers = () => {
    let chatMembers = this.state.originalGroups;
    let searchKeyword = this.state.searchMembersInput;

    let searchResults = [];
    chatMembers.map((chatRoom) => {
      chatRoom.chatMembers.map((chatMember) => {
        if (
          chatMember.name.toLowerCase().includes(searchKeyword.toLowerCase())
        ) {
          searchResults.push(chatRoom);
        }
        return null;
      });
      return null;
    });

    this.setState({
      groups: searchResults,
    });
  };
  searchKeyPress = (e) => {
    if (e.charCode === 13) this.searchMembers();
  };

  render() {
    const { groups, messages, roomName, inputMessage, searchMembersInput } =
      this.state;
    let roomProfileImage = this.state.roomProfileImage;
    if (typeof roomProfileImage === "undefined" || roomProfileImage === "")
      roomProfileImage = "/assets/img/avatars/default.jpg";
    let tempTime = "";
    let updateMessages = false;
    if (messages !== tmpMessages) {
      updateMessages = true;
    }
    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Chat</h1>
        <Button className="mb-2" onClick={() => this.modalOpen("modalNewChat")}>
          <ChatIcon size={18} /> New Chat
        </Button>

        <Card className="border">
          <Row noGutters>
            <Col lg={5} xl={3} className="border-right">
              <div className="py-2 px-4">
                <div className="align-items-center py-3 text-center">
                  <b>Chats Available</b>
                </div>
              </div>

              <div className="flex-grow-0 p-2 border-top">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder="Search Member Name"
                    onChange={this.onChange}
                    onKeyPress={this.searchKeyPress}
                    name="searchMembersInput"
                    id="searchMembersInput"
                    value={searchMembersInput}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="primary"
                      onClick={() => this.searchMembers()}
                    >
                      <SearchIcon size={18} /> SEARCH
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </div>

              <div className="chat-list">
                {groups.length === 0 && <Loader />}
                {groups.length > 0 &&
                  groups.map((chatGroup, index) => {
                    if (chatGroup.chatMembers[0] !== false) {
                      if (chatGroup.chatMembers.length !== 0) {
                        let groupAvatarImage = "";
                        if (
                          typeof groupAvatarImage === "undefined" ||
                          groupAvatarImage === ""
                        ) {
                          groupAvatarImage = chatGroup.chatMembers[0].image;
                          if (groupAvatarImage === "")
                            groupAvatarImage =
                              "/assets/img/avatars/default.jpg";
                        }
                        return (
                          <a
                            key={index}
                            href={`/chat/${chatGroup.roomId}`}
                            className="text-dark"
                          >
                            <ChatName
                              key={index}
                              chatMembers={chatGroup.chatMembers}
                              avatar={groupAvatarImage}
                              name={
                                chatGroup.firstName + " " + chatGroup.lastName
                              }
                              numbers={chatGroup.messageCount}
                              online="false"
                            />
                          </a>
                        );
                      } else return null;
                    } else return null;
                  })}
              </div>
            </Col>
            <Col lg={7} xl={9}>
              {roomName !== "" && (
                <div className="p-2 border-bottom d-none d-lg-block">
                  <Media className="align-items-center py-1">
                    <div className="position-relative">
                      <img
                        src={roomProfileImage}
                        className="rounded-circle mr-1"
                        alt={roomName}
                        width="40"
                        height="40"
                      />
                    </div>
                    <Media body className="pl-3">
                      <strong>{roomName}</strong>
                    </Media>
                  </Media>
                </div>
              )}

              <div className="position-relative">
                <div
                  className="chat-messages p-2"
                  ref={(messageRef) => {
                    this.messagesRef = messageRef;
                  }}
                >
                  {updateMessages === true &&
                    messages.length > 0 &&
                    messages.map((message, index) => {
                      let messageImg = "";
                      if (
                        typeof messageImg === "undefined" ||
                        messageImg === ""
                      ) {
                        messageImg = message.image;
                        if (messageImg === "")
                          messageImg = "/assets/img/avatars/default.jpg";
                      }

                      if (tempTime === "" || tempTime !== message.day) {
                        tempTime = message.day;
                        return (
                          <Fragment key={index}>
                            <ChatMessage type="Date">{message.day}</ChatMessage>
                            <ChatMessage
                              position="right"
                              source={message.source}
                              name={message.name}
                              avatar={messageImg}
                              time={message.time}
                              type={message.messageType}
                              authToken={this.props.auth.token}
                              token={message.token}
                            >
                              {message.message}
                            </ChatMessage>
                          </Fragment>
                        );
                      } else {
                        return (
                          <ChatMessage
                            key={index}
                            position="right"
                            source={message.source}
                            name={message.name}
                            avatar={messageImg}
                            time={message.time}
                            type={message.messageType}
                            authToken={this.props.auth.token}
                            token={message.token}
                          >
                            {message.message}
                          </ChatMessage>
                        );
                      }
                    })}
                  {messages.length === 0 && (
                    <div>select someone to chat with on the left</div>
                  )}
                </div>
              </div>

              {roomName !== "" && (
                <div className="flex-grow-0 p-2 border-top">
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Type your message"
                      onChange={this.onChange}
                      onKeyPress={this.keyPress}
                      name="inputMessage"
                      id="inputMessage"
                      value={inputMessage}
                    />
                    <InputGroupAddon addonType="append">
                      <Button
                        color="primary"
                        onClick={() => this.sendMessage()}
                      >
                        <SendIcon size={18} /> Send
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              )}
            </Col>
          </Row>
        </Card>

        <Modal
          title="Start New Chat"
          show={this.state.modalNewChat}
          onSave={() => this.onSaveNewChat()}
          handleClose={() => this.modalClose("modalNewChat")}
          name={"modalNewChat"}
          saveButton={"Start Chatting"}
        >
          <FormInput
            field={{ fieldName: "newChat" }}
            type={"dropdown"}
            id={"newChat"}
            dropdown={this.state.dataResults["addChatMemberList"]}
            name={"newChat"}
            label={"Add new chat"}
            placeholder={"enter new chat"}
            value={this.state.dataResults["newChat"]}
            defaultValue={this.state.dataResults["newChat"]}
            colSize={1}
            onChange={this.onInputChange}
          />
          (to chat with a mentee not listed here, go to the manage mentees
          section and click on the chat icon <ChatIcon size={18} />)
        </Modal>
      </Container>
    );
  }
}

const ChatName = ({ numbers, chatMembers, avatar }) => {
  if (chatMembers[0] !== false) {
    return (
      <ListGroupItem
        action
        className="border-bottom-0 border-top border-left-0 border-right-0"
      >
        {numbers !== "0" && (
          <Badge color="success" className="float-right">
            {numbers}
          </Badge>
        )}
        {chatMembers.length === 1 && (
          <Media>
            <img
              src={avatar}
              className="rounded-circle mr-1"
              alt={chatMembers[0].name}
              width="40"
              height="40"
            />
            <Media body className="ml-3">
              {chatMembers[0].name}
            </Media>
          </Media>
        )}
      </ListGroupItem>
    );
  } else return null;
};
const ChatMessage = ({
  avatar,
  name,
  children,
  time,
  source,
  type,
  token,
  authToken,
}) => {
  if (typeof avatar === "undefined" || avatar === "") {
    avatar = "/assets/img/avatars/default.jpg";
  }

  let position = "left";
  if (source === "from" || token === authToken) position = "right";
  if (source === "to") position = "left";
  if (type === "Notice") {
    return (
      <div className="text-center bg-light rounded py-2 px-3 mb-3">
        {children}
      </div>
    );
  }
  if (type === "Date") {
    return (
      <div className="col-12 text-center mb-3">
        <hr noshade="true" size="2" />
        <small>{children}</small>
      </div>
    );
  }

  return (
    <div className={`chat-message-${position} pb-4`}>
      <div>
        <img
          src={avatar}
          className="rounded-circle mr-1"
          alt={name}
          width="40"
          height="40"
        />
        <div className="text-muted small text-nowrap mt-2">{time}</div>
      </div>
      {position !== "right" && (
        <div
          className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
            position === "right" ? "mr-3" : "ml-3"
          }`}
        >
          <div className="font-weight-bold mb-1">{name}</div>
          {children}
        </div>
      )}
      {position !== "left" && (
        <div
          className={`flex-shrink-1 bg-primary text-white rounded py-2 px-3 ${
            position === "right" ? "mr-3" : "ml-3"
          }`}
        >
          <div className="font-weight-bold mb-1">{name}</div>
          {children}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  data: state.pages.data,
  rooms: state.pages.data.rooms,
});

export default connect(mapStateToProps, {
  getChatRooms,
  getChatMessages,
  startNewChat,
})(Chat);
