import React, {Component} from "react";
import {timezones, serviceAreas, languageText} from "../config";
import DataOverview from "../chcomponents/DataOverview";
import Loader from "../components/Loader";
import {connect} from "react-redux";
import {getList} from "../client/actions/apiActions";

const tableName = "organizations";

class Organizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "status",
                    type: "bool",
                    sort: false,
                },
                {
                    dataField: "name",
                    sort: true
                },
                {
                    dataField: "agencyType",
                    sort: true
                },
                {
                    dataField: "email",
                    sort: true
                },
                {
                    dataField: "address",
                    sort: true
                },
                {
                    dataField: "tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                    requiredMessage: "Organization Name is required!"
                },
                {
                    fieldName: "status",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "cellPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "workPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "faxPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "email",
                    fieldType: "email",
                    colSize: 2
                },
                {
                    fieldName: "website",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "photo",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "notes",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "timezones",
                    fieldType: "dropdown",
                    dropdownData: timezones,
                    colSize: 3
                },
                {
                    fieldName: "agencyType",
                    fieldType: "dropdown",
                    dropdownData: [],
                    colSize: 3
                },
                {
                    fieldName: "addressRadius",
                    fieldType: "dropdown",
                    dropdownData: serviceAreas,
                    colSize: 3,
                    defaultValue: 25
                },
                {
                    fieldName: "agencyServices",
                    fieldType: "checkbox",
                    valueOptions: [],
                    colSize: 1
                },
                {
                    fieldName: "address",
                    fieldType: "geoLocate",
                    colSize: 1
                },
                {
                    fieldName: "addressLatitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "addressLongitude",
                    fieldType: "hidden",
                    colSize: 0
                },
            ],
            fieldDataDefault: {
                name: '',
                status: true,
                cellPhone: '',
                workPhone: '',
                faxPhone: '',
                email: '',
                website: '',
                notes: '',
                timezones: '',
                agencyType: '',
                serviceArea: '',
                address: '',
                addressLatitude: '',
                addressLongitude: ''
            },
        }
    }
    componentDidMount() {
        this.props.getList('configAgencyTypes', ['configAgencyServices']);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (typeof(this.props.data.configAgencyTypes) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'agencyType') {
                        fieldData[index].dropdownData = this.props.data.configAgencyTypes;
                    }
                    return null;
                });
                this.setState({pageMode: 'data', fieldData});
            }
            if (typeof(this.props.data.configAgencyServices) !== 'undefined') {
                let fieldData = this.state.fieldData;
                fieldData.map((data, index) => {
                    if (data.fieldName === 'agencyServices') {
                        fieldData[index].valueOptions = this.props.data.configAgencyServices;
                    }
                    return null;
                });
                this.setState({pageMode: 'data', fieldData});
            }
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault, pageMode } = this.state;
        let languageCode = this.props.auth.user.languageCode;
        if (pageMode === 'loading')
        {
            return (<Loader />);
        }
        else
        {
            return (<DataOverview
                tableName={tableName}
                pageTitle={languageText[languageCode].components[tableName].pageTitle}
                listTitle={languageText[languageCode].components[tableName].listTitle}
                listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
                addTitle={languageText[languageCode].components[tableName].addTitle}
                addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
                editTitle={languageText[languageCode].components[tableName].editTitle}
                editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
                languageInfo={languageText[languageCode]}
                tableColumns={tableColumns}
                fieldData={fieldData}
                fieldDataDefault={fieldDataDefault}
            />);
        }
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getList} )(Organizations);
