import React, {Component, Fragment} from "react";
import {CardHeader, Button, Card, Col, Container, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {connect} from "react-redux";
import { getRecord, getChatRooms, getChatMessages, archiveRecord } from "../client/actions/apiActions";
import {ChatServer} from "../config";
import Loader from "../components/Loader";
import socketIOClient from 'socket.io-client';
import MenteeSubNav from "../chcomponents/MenteeSubNav";
import {Link} from "react-router-dom";

let tmpMessages = [];
let socket;

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputMessage: '',
      groups: [],
      messages: [],
      menteeId:0,
      roomId:0,
      roomName: '',
      roomProfileImage: '',
      menteeName: '',
      timestamp: 'no timestamp yet'
    }
    socket = socketIOClient(ChatServer, {
      transports: ['polling'],
      forceNew: true,
      reconnectionDelay: 3000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      jsonp: false,
      secure: true,
    });
  }
  componentDidMount() {
    let menteeId = this.props.match.params.menteeId;
    let pathName = this.props.match.path.replace("/:menteeId", "");
    this.setState({ menteeId, pathName });
    this.props.getRecord('mentee', menteeId);
    this.configureSocket();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      if (this.props.data.dataType === "chatMessages")
      {
        let chatMessages = this.props.data.chatMessages;
        if (chatMessages.length === 0) {
          chatMessages = [];
        }
        this.setState({
          messages : chatMessages,
          roomProfileImage : this.props.data.roomProfileImage,
          roomName : this.props.data.roomName
        });
        socket.emit('submitToken', this.state.roomId, this.props.auth.token);
      }
      if (this.props.data.dataType === "getRecord")
      {
        this.selectChatRoom(this.props.data.chatRoomId);
        this.setState({menteeName: this.props.data.dataValues.firstName + " " + this.props.data.dataValues.lastName});
      }
    }
    this.scrollToBottom();
  }
  configureSocket  = () => {
    socket.on('connect', function onConnect() {
      socket.emit('connected');
    });
    socket.on('message', (message) => {
      let messages = this.state.messages;
      messages.push(message);
      this.setState({messages});
      this.scrollToBottom();
    });
  }
  selectChatRoom = (roomId) => {
    this.setState({roomId});
    this.props.getChatMessages(roomId);
    socket.emit('joinRoom', this.state.roomId);
  };
  updateChatHistory(entry) {
    this.setState({ messages: this.state.messages.concat(entry) })
  }
  sendMessage() {
    const { inputMessage } = this.state;
    if (!inputMessage) return;
    socket.emit('chatMessage', {message: inputMessage, messageType: 'Message'});
    return this.setState({ inputMessage: '' });
  }
  keyPress = (e) => {
    if (e.charCode === 13) this.sendMessage();
  };
  onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
  };
  scrollToBottom() {
      this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
  };
  deactivateMentee = () => {
    this.props.archiveRecord("mentee", this.state.menteeId);
  }
  render() {
    const { messages, roomName, inputMessage, menteeId, pathName, menteeName } = this.state;
    let tempTime = "";
    let updateMessages = false;
    if (messages !== tmpMessages) {
      updateMessages = true;
    }

    return (<Container fluid className="p-0">
        <Row className="mb-4">
          <Col>
            <Link className="btn btn-sm btn-secondary" to="/mentee/manage">Back to Mentees</Link>
          </Col>
          <Col className="text-right">
            Viewing : {menteeName}
          </Col>
        </Row>
      <Row>
        <Col md="3" xl="2">
          <MenteeSubNav id={menteeId} deactivateMentee={this.deactivateMentee} linkActive={pathName}/>
        </Col>
        <Col md="9" xl="10">
        <Card>
          <CardHeader>
            <h1 className="h3 mb-3">Chat</h1>
          </CardHeader>

          <div className="position-relative">
            <div className="chat-messages p-4" ref={messageRef => {this.messagesRef = messageRef;} }>
              {updateMessages === true && messages.length > 0 && messages.map((message, index) => {

                let avatar = message.image;
                if (typeof(avatar) === 'undefined' || avatar === '') avatar = "/assets/img/avatars/default.jpg";

                if (tempTime === "" || tempTime !== message.day) {
                  tempTime = message.day;
                  return (<Fragment key={index}>
                    <ChatMessage
                        type="Date"
                    >
                      {message.day}
                    </ChatMessage>
                    <ChatMessage
                        position="right"
                        source={message.source}
                        name={message.name}
                        avatar={avatar}
                        time={message.time}
                        type={message.messageType}
                        authToken={this.props.auth.token}
                        token={message.token}
                    >
                      {message.message}
                    </ChatMessage>
                  </Fragment>);
                }

                return (<ChatMessage
                    key={index}
                    position="right"
                    source={message.source}
                    name={message.name}
                    avatar={avatar}
                    time={message.time}
                    type={message.messageType}
                    authToken={this.props.auth.token}
                    token={message.token}
                >
                  {message.message}
                </ChatMessage>);
              })}
              {messages.length === 0 && <Loader />}
            </div>
          </div>

          {roomName !== '' && <div className="flex-grow-0 p-2 border-top">
            <InputGroup>
              <Input type="text" placeholder="Type your message" onChange={this.onChange} onKeyPress={this.keyPress} name="inputMessage" id="inputMessage" value={inputMessage} />
              <InputGroupAddon addonType="append">
                <Button color="primary" onClick={() => this.sendMessage()}>Send</Button>
              </InputGroupAddon>
            </InputGroup>
          </div>}
        </Card>
        </Col>
      </Row>

    </Container>);
  }
}

const ChatMessage = ({ avatar, name, children, time, source, type, token, authToken }) => {
  let position = "left";
  if (source === 'from' || token === authToken) position = 'right';
  if (source === 'to') position = 'left';
  if (type === 'Notice') {
    return (<div className="text-center bg-light rounded py-2 px-3 mb-3">
      {children}
    </div>);
  }
  if (type === 'Date') {
    return (<div className="col-12 text-center mb-3">
      <hr noshade="true" size="2" />
      <small>{children}</small>
    </div>);
  }

  return (<div className={`chat-message-${position} pb-4`}>
    <div>
      <img
          src={avatar}
          className="rounded-circle mr-1"
          alt={name}
          width="40"
          height="40"
      />
      <div className="text-muted small text-nowrap mt-2">{time}</div>
    </div>
    {position !== 'right' && <div
        className={`flex-shrink-1 bg-light rounded py-2 px-3 ${
            position === "right" ? "mr-3" : "ml-3"
        }`}
    >
      <div className="font-weight-bold mb-1">{name}</div>
      {children}
    </div>}
    {position !== 'left' && <div
        className={`flex-shrink-1 bg-primary text-white rounded py-2 px-3 ${
            position === "right" ? "mr-3" : "ml-3"
        }`}
    >
      <div className="font-weight-bold mb-1">{name}</div>
      {children}
    </div>}

  </div>);
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data
});

export default connect(mapStateToProps, {getRecord, getChatRooms, getChatMessages, archiveRecord} )(Chat);
