import React, {Fragment} from 'react';
import classnames from "classnames";
import ShowInputError from "./ShowInputError";

const FormInputRadio = ({name, value, label, colSize, placeholder, field, type, helpText, onChange, error, valueOptions, defaultValue}) => {
    let divColSize;
    if (colSize === 0) divColSize = 0;
    if (colSize === 1) divColSize = 12;
    if (colSize === 2) divColSize = 6;
    if (colSize === 3) divColSize = 4;
    if (colSize === 4) divColSize = 8;
    if (colSize === 5) divColSize = 3;
    return (<div className={`col-12 align-bottom col-sm-${divColSize}`}>
            <div className="container p-0">
                <div className="form-group row border-bottom pb-2">
                    <label className={classnames('col-sm-12 m-0 pt-2 pb-2', { 'is-invalid': error})}>
                        {label}
                        {field.required === true && <Fragment>{' '} <small className="text-danger">* Required field</small></Fragment>}
                    </label>
                    <div className="col-sm-12">
                    {valueOptions ? (valueOptions.map((options, index) => {
                        let defaultChecked = null;
                        if (parseInt(value) === parseInt(options.value)) defaultChecked = 'checked';
                        else if (value === options.value) defaultChecked = 'checked';
                        else if (defaultValue === options.value && value === '') defaultChecked = 'checked';

                        return (<div key={index} className="form-check form-check-inline">
                            <input
                                className={classnames('form-check-input', {'is-invalid': error})}
                                type="radio"
                                defaultChecked={defaultChecked}
                                name={name}
                                id={name + index}
                                value={options.value}
                                onChange={(e) => onChange(type, e, field)}
                            />
                            <label className="form-check-label" htmlFor={name + index}>
                                {options.name}
                            </label>
                        </div>);
                    })) : null
                    }
                </div>
                {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
                {error && <ShowInputError error={error} />}
            </div>
            </div>
        </div>
    )
};

export default FormInputRadio