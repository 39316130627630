import React from 'react'
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '500px'
};

const circleOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.4,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
}

function AdddressGoogleMap({lat, lng, radius}) {
    let latitude = parseFloat(lat);
    let longitude = parseFloat(lng);

    let center = {lat: latitude, lng: longitude};
    //const [setMap] = React.useState(null)

    React.useEffect(() => {

    })
    const onLoad = React.useCallback(function callback(map) {

    }, [])
    const onUnmount = React.useCallback(function callback() {
        //setMap(null)
    }, [])

    if (radius !== '' && typeof(radius) !== 'undefined' && radius !== null) {
        radius = ((radius * 1.60934) * 1000);
    }

    return (<GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={9}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
        <Marker position={center} />
        {(radius !== '' && typeof(radius) !== 'undefined' && radius !== null)  && <Circle center={center} radius={radius} options={circleOptions} />}
    </GoogleMap>)
}

export default React.memo(AdddressGoogleMap)
